import { useNavigate, Link } from "react-router-dom";
import { Button, Flex, HStack, Image, Box, VStack, Heading } from '@chakra-ui/react'
import NotFoundPicture from "../Resources/Images/notfound.svg";

const NotFoundComponent = () => {
    const navigate = useNavigate();

    return <Flex
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
    >
        <Flex
            maxW={"lg"}
            align="center"
            justify="center"
            wrap="wrap"
            padding="1rem"
            color="gray.800"
        >
            <VStack
                spacing={1}
            >
                <Box>
                    <Image src={NotFoundPicture} alt="Not found component" />
                </Box>
                <Heading as='h1' size='md' color={"gray.600"}>
                    Ooops la page demandé n'existe pas.
                </Heading>
            </VStack>

            <HStack
                mt={4}
                spacing={4}
                align='stretch'
            >
                <Button as={Link} to={"/"} colorScheme="brand">Page d'accueil</Button>
                <Button as={Link} to={-1}>Page précédente</Button>
            </HStack>
        </Flex>
    </Flex>
}

export default NotFoundComponent;