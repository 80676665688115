import { Box, Flex, Image, Tag, Text } from '@chakra-ui/react';
import { React, useEffect, useState } from 'react';
import getImageFromS3 from '../Utils/ImageFromS3';
import { GetLabelFromType } from "../Utils/QuizzesUtils";
import DynamicComponent from './DynamicComponent';

const ExamQuestionCard = ({ description, type, image = undefined, ...props }) => {
    const [questionPicture, setQuestionPicture] = useState(null)

    useEffect(() => {
        if (image !== undefined) {
            getImageFromS3(image).then(pictureBlob => {
                if (!pictureBlob) {
                    return;
                }
                const tempQuestionPicture = URL.createObjectURL(pictureBlob);
                setQuestionPicture(tempQuestionPicture);
            })
        }
    }, [image])

    return (
        <Box w='100%' boxShadow={"base"} p={4} color="gray.700">
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{description}</Text>
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType(type)}</Tag>
            </Flex>
            {image && <Image width={"300px"} objectFit={"cover"} src={questionPicture} mb={4} />}
            <DynamicComponent type={type} {...props}></DynamicComponent>

        </Box>
    )
}

export default ExamQuestionCard