import csrfFetch from '../Utils/CSRF';

export const getTeamsByUser = async () => {
    const response = await csrfFetch('/teams/getteamsbyuser');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        return await response.json();
    }

    return [];
}

export const addOrRemoveJobOffer = async (candidateId, jobOfferId) => {
    return await csrfFetch(`/users/candidat/${candidateId}/addOrDeleteJobOffer/${jobOfferId}`, {
        method: 'PUT',
    });
}

export const getCandidate = async (candidateId, setData) => {
    const response = await csrfFetch(`/users/candidat/${candidateId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data);
    }
}

export const updateCandidatStatus = async (candidateId, status, setCandidatStatus) => {
    await csrfFetch(`/users/update_status/${candidateId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            candidateId: candidateId,
            status: status
        })
    });
    setCandidatStatus(status)
}