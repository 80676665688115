import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Avatar,
    Box,
    Button,
    Divider,
    Flex,
    IconButton,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaFileContract, FaPlus, FaTrash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { addOrRemoveJobOffer } from "../API/Candidats";
import { getJobOffersByTeamId } from "../API/JobOffers";
import { removeCandidate } from "../API/Teams";
import { UserContext } from '../Authentication/ProtectedRoute';
import EmptyCandidates from "../Resources/Images/empty_candidates.svg";
import StatusBadge from '../Utils/StatusBadge';
import PaginationTable from './PaginationTable';
import { useSortableData } from './useSortableData';
import { getJobOffersByUserTeams } from "../API/JobOffers";


const PAGINATION_SIZE = 10;

const CandidatsTable = ({ candidats, reload }) => {
    const { user } = useContext(UserContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAddJobOffer, onOpen: onOpenAddJobOffer, onClose: onCloseAddJobOffer } = useDisclosure();
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const [candidateToAddJobOffer, setCandidateToAddJobOffer] = useState(null)
    const [jobOffers, setJobOffers] = useState(null)
    const [pageIndex, setPageIndex] = useState(0);
    const [paginationData, setPaginationData] = useState([]);
    const toast = useToast()
    const { items: sortedCandidats, requestSort, getSortDirection } = useSortableData(candidats);

    const navigate = useNavigate();

    useEffect(() => {
        const paged = sortedCandidats.slice(
            pageIndex * PAGINATION_SIZE,
            (pageIndex + 1) * PAGINATION_SIZE
        )

        setPaginationData(paged)
    }, [pageIndex, sortedCandidats])

    useEffect(() => {
        try {
            getJobOffersByUserTeams(setJobOffers);
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des offres d\'emploi.',
                status: 'error',
            })
        }
    }, [user.teams])

    const getActionByRole = (candidatTeam, candidatId) => {
        const workingTeam = user && user.teams && user.teams.find(team => team._id === candidatTeam)
        if (!workingTeam || !['administrator', 'member'].includes(workingTeam.role)) return <></>

        return (<>

            <Tooltip label="Ajouter une offre d'emploi" placement='top'>
                <IconButton
                    rounded="full"
                    variant="outline"
                    colorScheme='brand'
                    mr={1}
                    onClick={() => {
                        setCandidateToAddJobOffer(candidatId)
                        onOpenAddJobOffer()
                    }}
                    aria-label='Add job offer'
                    icon={<FaFileContract />}
                />
            </Tooltip>
            {workingTeam.role === "administrator" &&
                <>
                    <Tooltip label="Supprimer" placement='top'>
                        <IconButton
                            rounded="full"
                            onClick={() => {
                                setToDeletion({ candidatId: candidatId, teamId: candidatTeam })
                                onOpen()
                            }}
                            colorScheme='red'
                            aria-label='Delete'
                            icon={<FaTrash />}
                        />
                    </Tooltip>
                </>
            }
        </>)
    }

    const handleDeletion = async () => {
        try {
            const response = await removeCandidate(toDeletion.teamId, toDeletion.candidatId)

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                reload()

        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression du candidat',
                status: 'error',
            })
        }
    }

    const handleSubmitAddOrDeleteJobOffer = async (jobOfferId) => {
        try {
            const response = await addOrRemoveJobOffer(candidateToAddJobOffer, jobOfferId)

            if (!response.ok) {
                toast({
                    title: 'Erreur lors de la modification de l\'offre d\'emploi',
                    status: 'error',
                })

                throw new Error('Failed to fetch data');
            } else {
                reload()
                toast({
                    title: 'Offre d\'emploi modifiée avec succès'
                })
            }
        } catch (error) {
            toast({
                title: 'Erreur lors de la modification de l\'offre d\'emploi',
                status: 'error',
            })
        }
    }


    return candidats.length === 0 ? <Flex justifyContent={"center"}>
        <Box p={4}>
            <Image src={EmptyCandidates} alt="No candidates" maxHeight={500} />
            <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos candidats apparaîtrons ici !</Text>
        </Box>
    </Flex> : (
        <>
            <TableContainer width={"100%"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr userSelect={"none"}>
                            <Th w={"40px"} />
                            <Th onClick={() => requestSort('lastname')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nom</Text>{getSortDirection('lastname')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('firstname')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Prénom</Text>{getSortDirection('firstname')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('email')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Email</Text>{getSortDirection('email')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('team.name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Équipe</Text>{getSortDirection('team.name')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('status')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Statut</Text>{getSortDirection('status')}
                                </Flex>
                            </Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginationData.map((candidate, index) => (
                            <Tr key={index} cursor={"pointer"} _hover={{ bg: "gray.100" }} >
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>
                                    <Avatar
                                        size={'md'}
                                        name={`${candidate.firstname} ${candidate.lastname}`}
                                        src={candidate.blob}
                                    />
                                </Td>
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{candidate.lastname}</Td>
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{candidate.firstname}</Td>
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{candidate.email}</Td>
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{candidate.team.name}</Td>
                                <Td onClick={() => { navigate('/candidat/' + candidate._id) }}><StatusBadge status={candidate.status} /></Td>
                                <Td>{getActionByRole(candidate.team._id, candidate._id)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <PaginationTable pageSize={PAGINATION_SIZE} pageIndex={pageIndex} setPageIndex={setPageIndex} totalItemsCount={sortedCandidats.length} showQuantity={true} />
            <AlertDialog
                size={"xl"}
                isOpen={isOpenAddJobOffer}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setCandidateToAddJobOffer(null)
                    onCloseAddJobOffer()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Ajouter une offre d'emploi
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {jobOffers && jobOffers.map((jobOffer, index) => {
                                const isJobOfferAlreadyAdded = candidats.find(candidate => {
                                    return candidate._id === candidateToAddJobOffer && candidate.jobsId && candidate.jobsId.includes(jobOffer._id)
                                });

                                return <React.Fragment key={index}>
                                    <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                        <Text fontSize='sm'>{jobOffer.name}</Text>
                                        {!isJobOfferAlreadyAdded && <Button rounded={"full"} variant={"ghost"} rightIcon={<FaPlus />} size={"sm"} color={"brand"} onClick={async () => {
                                            await handleSubmitAddOrDeleteJobOffer(jobOffer._id);
                                        }}>Lier à cette offre</Button>}
                                        {isJobOfferAlreadyAdded && <Button rounded={"full"} rightIcon={<FaTrash />} size={"sm"} variant={"ghost"} color={"red"} onClick={async () => {
                                            await handleSubmitAddOrDeleteJobOffer(jobOffer._id, true);
                                        }}>Supprimer de cette offre</Button>}
                                    </Box>
                                    <Divider />
                                </React.Fragment>
                            })}
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={() => {
                                    setCandidateToAddJobOffer(null)
                                    onCloseAddJobOffer()
                                }}>
                                    Fermer
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le candidat <b>{toDeletion && toDeletion.candidatId}</b> de l'équipe <b>{toDeletion && toDeletion.teamId}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default CandidatsTable