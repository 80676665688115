import { Badge, Button, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { FaArrowRight, FaBusinessTime, FaFileSignature, FaMapPin, FaMoneyBill } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { displayOfferEndMessage } from '../../Utils/FormatDate'

const JobOfferCard = ({ job }) => {
    return (
        <Flex direction={"column"} px={4} py={4} height={"100%"} bg="lightBg.50">
            <Flex gap={2}>
                {
                    (job.salary && job.salary[0] && job.salary[1]) ? <Badge>
                        <Flex alignItems={"center"} gap={2}>
                            <FaMoneyBill /> {job.salary && job.salary[0]}€ - {job.salary && job.salary[1]}€
                        </Flex>
                    </Badge> : null
                }
                {
                    job.contractType ? <Badge>
                        <Flex alignItems={"center"} gap={2}>
                            <FaFileSignature /> {job.contractType}
                        </Flex>
                    </Badge> : null
                }
                {
                    job.contractTime ? <Badge>
                        <Flex alignItems={"center"} gap={2}>
                            <FaBusinessTime /> {job.contractTime}
                        </Flex>
                    </Badge> : null
                }
                {
                    job.expirationDate ? <Badge marginLeft="auto">{displayOfferEndMessage(job.expirationDate)}</Badge> : null
                }
            </Flex>
            {
                job.location ? <Flex gap={2} mt={2}>
                    <Badge>
                        <Flex alignItems={"center"} gap={2}>
                            <FaMapPin /> {job.location}
                        </Flex>
                    </Badge>
                </Flex> : null
            }
            <Heading mt={6} mb={2}>{job.name}</Heading>
            <Text>{job.description}</Text>
            <Flex gap={2} my={4} alignItems={"center"}>
                {job.tags.map((tag, index) => <Badge key={index}>
                    {tag}
                </Badge>)}
            </Flex>
            <Button as={Link} to={"/offres-emploi/" + job._id} marginLeft="auto" size="sm" rightIcon={<FaArrowRight />}>Visualiser l'offre d'emploi</Button>
        </Flex>
    )
}

export default JobOfferCard
