import { Box, Flex, Link, Stack, Text, Tag, HStack, Image, useMediaQuery, Button, Divider } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import HoritzontalLogo from '../Resources/Logos/aptitude_recrutement.svg';
import { useContext } from 'react'
import { UserContext } from '../Authentication/ProtectedRoute';

const Footer = ({ hidePartnerProgram }) => {
    const [isLargerThanMd] = useMediaQuery("(min-width: 48em)");
    const [isLargerThanLg] = useMediaQuery("(min-width: 62em)");
    const { user } = useContext(UserContext);

    return (
        <Box mt={10} pb={10} bg="white" height="200px">
            {(user && user.role !== "candidate" && !hidePartnerProgram) &&
                <Box py={5} bg={"lightBg.50"} mb={"100px"} borderRadius={"xl"}>
                    <Flex maxW={"8xl"} mx="auto" justify="space-between" align="center" direction={["column", "row"]} px={5} py={"50px"}>
                        <Flex direction="column" gap={3}>
                            <Text as={"b"} fontWeight={"semibold"} fontSize={"xl"} color="gray.700">Rejoignez le programme partenaire</Text>
                            <Text fontSize="md" color="gray.600">Adaptez votre logiciel de recrutement aux besoins de votre entreprise.</Text>
                        </Flex>
                        <Button
                            as="a"
                            href="https://aptitude-recrutement.fr/tarifs/"
                            target="_blank"
                            rel="noopener noreferrer"
                            colorScheme="brand"
                            size="xl"
                            height={"50px"}
                            width={"250px"}
                            rightIcon={<FaArrowRight />}
                            mt={["50px", 0]}
                        >
                            Rejoindre le programme
                        </Button>
                    </Flex>
                </Box>
            }
            {hidePartnerProgram && <Divider my={8} />}
            <Flex
                maxW={"1800px"}
                mx="auto"
                align="start"
                px={5}
                wrap={isLargerThanLg ? "wrap" : "nowrap"}
                flexDirection={isLargerThanLg ? "row" : "column"}
                gap={8}
                pt={user && user.role === "candidate" ? 10 : 0}
            >
                <Stack flex={3} mx="auto">
                    <HStack>
                        <Image w="250px" src={HoritzontalLogo} alt="logo aptitude" />
                    </HStack>
                    <Text>Sélectionner l'excellence avec simplicité.</Text>
                </Stack>

                <Flex
                    gap={10}
                    mx="auto"
                    w={isLargerThanLg ? "60%" : "100%"}
                    pb={5}
                >
                    <Stack flex={2} w={isLargerThanMd ? "30%" : "100%"} >
                        <Text fontWeight="semibold" color={"gray.500"} fontSize="sm">INFORMATIONS</Text>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://aptitude-recrutement.fr/services/" target="_blank" rel="noopener noreferrer">Nos services</Link>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://aptitude-recrutement.fr/tarifs/" target="_blank" rel="noopener noreferrer">Nos tarifs</Link>
                        <HStack>
                            <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://aptitude-recrutement.fr/participer-beta/" target="_blank" rel="noopener noreferrer">Participer à la bêta</Link>
                            <Tag size="sm" colorScheme="brand">
                                Gratuit
                            </Tag>
                        </HStack>
                    </Stack>

                    <Stack flex={2} w={isLargerThanMd ? "30%" : "100%"} display={isLargerThanLg ? "flex" : "none"}>
                        <Text fontWeight="semibold" color={"gray.500"} fontSize="sm">NOUS SUIVRE</Text>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://aptitude-recrutement.fr/">Site web</Link>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="#">Instagram</Link>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://www.linkedin.com/company/aptitude-recrutement">Linkedin</Link>
                    </Stack>

                    <Stack flex={2} w={isLargerThanMd ? "30%" : "100%"} >
                        <Text fontWeight="semibold" color={"gray.500"} fontSize="sm">À PROPOS</Text>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="/mentions-legales">Mentions légales</Link>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://aptitude-recrutement.fr/contact/" target="_blank" rel="noopener noreferrer">Nous contacter</Link>
                        <Link color={"gray.600"} fontSize="md" fontWeight={"semibold"} href="https://start-win.fr/" target="_blank" rel="noopener noreferrer">Start win</Link>
                    </Stack>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Footer;

