import csrfFetch from '../Utils/CSRF';

export const removeQuiz = async (id) => {
    const response = await csrfFetch('/quizzes/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    return response;
}

export const loadUserQuizzes = async (setData) => {
    const response = await csrfFetch('/quizzes/get-user-quizzes');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    }
}

export const loadQuizById = async (id, setData) => {
    const response = await csrfFetch(`/quizzes/get-user-quiz/${id}`);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data);
        return data;
    }
}

export const updateQuiz = async (data) => {
    return await csrfFetch('/quizzes/update-by-id', {
        method: 'POST',
        body: data
    })
}

export const createFromGoogle = async (data) => {
    return await csrfFetch('/quizzes/createFromGoogle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });
}