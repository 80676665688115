import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormErrorMessage, FormLabel, Input, Tag, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Authentication/ProtectedRoute';
import { handleFormErrors } from '../../Utils/HandleFormErrors';
import { updateUserProfile } from '../../API/Users';
import CopyToClipboard from '../../Utils/CopyToClipboard';

const DrawerUpdateProfil = ({ isOpen, onClose, btnRef }) => {
    const { user, loadData } = useContext(UserContext);
    const [userUpdate, setUserUpdate] = useState({});
    const toast = useToast();
    const [errors, setErrors] = useState({});

    const handleSubmit = async () => {
        try {
            let { lastname, firstname, email, phone, oldPassword, newPassword, newPasswordConfirmation } = userUpdate;
            const response = await updateUserProfile(lastname, firstname, email, phone, oldPassword, newPassword, newPasswordConfirmation);
            const data = await response.json();

            if (!response.ok) {
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }
                throw new Error('Merci de corriger les champs ci-dessus.');
            } else {
                setErrors({});
                toast({
                    description: "Votre profil a bien été modifié !"
                })
                loadData();
            }

        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            })
        }
    }

    const copyIdentifiant = async () => {
        let status = await CopyToClipboard(user?._id)
        if (status) {
            toast({
                title: 'Presse papier.',
                description: `Votre identifiant a bien été copié.`,
            })
        } else {
            toast({
                title: 'Presse papier.',
                description: `Votre identifiant n'a pas pu être copié.`,
                status: 'error',
            })
        }
    }

    useEffect(() => {
        setUserUpdate(user);
    }, [user])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            size='md'
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Modifier mon profil</DrawerHeader>
                <DrawerBody>
                    <FormLabel>Votre identifiant</FormLabel>
                    <Tag ml={"auto"} mb={4} cursor={"pointer"} background={"gray.100"} _hover={{ bg: "gray.200" }} fontSize='xs' onClick={copyIdentifiant} width={"fit-content"} >{user._id}</Tag>
                    <FormControl isInvalid={errors && errors.firstname} mb={4} isRequired>
                        <FormLabel>Prénom</FormLabel>
                        <Input required type='text' value={userUpdate.firstname} onChange={(e) => setUserUpdate({ ...userUpdate, firstname: e.target.value })} />
                        {errors && errors.firstname && <FormErrorMessage>{errors.firstname}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.lastname} mb={4} isRequired>
                        <FormLabel>Nom</FormLabel>
                        <Input type='text' value={userUpdate.lastname} onChange={(e) => setUserUpdate({ ...userUpdate, lastname: e.target.value })} />
                        {errors && errors.lastname && <FormErrorMessage>{errors.lastname}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.email} mb={4} isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input type='email' value={userUpdate.email} onChange={(e) => setUserUpdate({ ...userUpdate, email: e.target.value })} />
                        {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.phone} mb={4}>
                        <FormLabel>Phone</FormLabel>
                        <Input type='email' value={userUpdate.phone} onChange={(e) => setUserUpdate({ ...userUpdate, phone: e.target.value })} />
                        {errors && errors.phone && <FormErrorMessage>{errors.phone}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.oldPassword} mb={4}>
                        <FormLabel>Ancien mot de passe</FormLabel>
                        <Input type='password' onChange={(e) => setUserUpdate({ ...userUpdate, oldPassword: e.target.value })} />
                        {errors && errors.oldPassword && <FormErrorMessage>{errors.oldPassword}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.newPassword} mb={4}>
                        <FormLabel>Mot de passe</FormLabel>
                        <Input type='password' onChange={(e) => setUserUpdate({ ...userUpdate, newPassword: e.target.value })} />
                        {errors && errors.newPassword && <FormErrorMessage>{errors.newPassword}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors && errors.newPassword} mb={4}>
                        <FormLabel>Confirmation du mot de passe</FormLabel>
                        <Input type='password' onChange={(e) => setUserUpdate({ ...userUpdate, newPasswordConfirmation: e.target.value })} />
                        {errors && errors.newPassword && <FormErrorMessage>{errors.newPassword}</FormErrorMessage>}
                    </FormControl>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Annuler
                    </Button>
                    <Button colorScheme='brand' onClick={handleSubmit}>Enregistrer</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default DrawerUpdateProfil