import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, IconButton, Input, Select, Text, useToast } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { addAttachement } from '../../../API/Attachements';
import { UserContext } from '../../../Authentication/ProtectedRoute';
import { handleFormErrors } from '../../../Utils/HandleFormErrors';
import AttachmentFileUploader from '../AttachmentFileUploader';

const AddAttachmentDialog = ({ isOpen, cancelRef, onClose, candidat, reload }) => {
    const [name, setName] = useState("")
    const { user } = useContext(UserContext)
    const [teamId, setTeamId] = useState(user.teams[0]._id)
    const [files, setFiles] = useState(null)
    const [errors, setErrors] = useState({});
    const toast = useToast();

    const uploadFileAndPreview = (files) => {
        setFiles(files)
    }

    const uploadFileAndHandleChange = async () => {
        const formData = new FormData();

        formData.append('teamId', teamId);
        formData.append('candidateId', candidat._id);

        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files[]', files[i]);
            }
        }

        const response = await addAttachement(formData)

        const data = await response.json();
        if (!response.ok) {
            toast({
                title: "Erreur lors de l'ajout de la pièce jointe",
                description: data.message,
                status: "error"
            })

            if (data.errors) {
                handleFormErrors(setErrors, data.errors);
            } else {
                throw new Error('Failed to fetch data');
            }
        } else {
            toast({
                title: "Pièce jointe ajoutée",
                description: `La pièce jointe a bien été ajoutée au candidat.`

            })
            setFiles(null)
            setName("")
            setTeamId(user.teams[0]._id)
            reload()
            onClose()
        }
    }

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                setName("")
                setFiles(null)
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Ajouter une pièce jointe
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Flex p={4} direction={"column"} gap={4}>
                            <FormControl isInvalid={errors && errors.team} mb={4}>
                                <FormLabel>Choix de l'équipe</FormLabel>
                                <Select defaultValue={user.teams[0]} onChange={(e) => setTeamId(e.target.value)}>
                                    {user.teams.map(team => (
                                        <option key={team._id} value={team._id}>{team.name}</option>
                                    ))}
                                </Select>
                                {errors && errors.teamId && <FormErrorMessage>{errors.team}</FormErrorMessage>}
                            </FormControl>

                            <FormControl isInvalid={errors && errors.files} mb={4}>
                                {
                                    files ? Array.from(files).map((file, index) => (
                                        <Flex key={index} justifyContent={"space-between"} gap={8} mb={2}>
                                            <Text>{file.name}</Text>
                                            <IconButton rounded={"full"} size={"sm"} colorScheme='red' icon={<FaTrash />} onClick={() => {
                                                setFiles(Array.from(files).filter((f) => f.name !== file.name))
                                            }} />
                                        </Flex>
                                    )) : null
                                }
                                <AttachmentFileUploader
                                    handleChange={(file) => uploadFileAndPreview(file)}
                                    isInvalid={errors && errors.files}
                                />
                                {errors && errors.files && <FormErrorMessage>{errors.files}</FormErrorMessage>}
                            </FormControl>
                        </Flex>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Flex gap={2}>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='brand' ref={cancelRef} onClick={uploadFileAndHandleChange}>
                                Enregistrer
                            </Button>
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default AddAttachmentDialog
