import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    IconButton,
    Image,
    Spinner,
    Table,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaPenToSquare, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { removeQuiz } from '../API/Quizzes';
import EmptyQuizzes from "../Resources/Images/empty_questionnaires.svg";
import { convertTypeToLabel } from '../Utils/QuizzesUtils';
import PaginationTable from './PaginationTable';
import { useSortableData } from './useSortableData';

const PAGINATION_SIZE = 10;

const QuizzesTable = ({ loading, quizzes, reload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)

    const [pageIndex, setPageIndex] = useState(0);
    const [paginationData, setPaginationData] = useState([]);

    const { items: sortedQuizzes, requestSort, getSortDirection } = useSortableData(quizzes);

    const navigate = useNavigate();

    useEffect(() => {
        const paged = sortedQuizzes.slice(
            pageIndex * PAGINATION_SIZE,
            (pageIndex + 1) * PAGINATION_SIZE
        )

        if (pageIndex > 0 && paged.length === 0) {
            setPageIndex(pageIndex - 1)
        }

        setPaginationData(paged)
    }, [pageIndex, sortedQuizzes])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    // We can use an object to map permission to label instead of using if/else
    const permissionToLabel = {
        own: { colorScheme: "green", label: "Propriétaire" },
        write: { colorScheme: "teal", label: "Écriture" },
        read: { colorScheme: "yellow", label: "Lecture" },
        default: { colorScheme: "gray", label: "Aucune" }
    };
    const convertPermissionToLabel = (permission) => (
        <Tag colorScheme={permissionToLabel[permission]?.colorScheme ?? permissionToLabel.default.colorScheme}>
            {permissionToLabel[permission]?.label ?? permissionToLabel.default.label}
        </Tag>
    );

    const getBadgeFromTags = (tags) => {
        return (tags ?? []).map((tag, index) => (
            <Tag key={index} mr={1}>{tag?.toUpperCase()}</Tag>
        ))
    }

    const getActionsFromPermission = (permission, id) => {
        if (permission === "own" || permission === "write") {
            return <>
                <Tooltip label='Mettre à jour' placement='top'>
                    <IconButton
                        variant="outline"
                        rounded="full"
                        as={Link}
                        to={`/questionnaire/update/${id}`}
                        aria-label='Update'
                        colorScheme='brand'
                        icon={<FaPenToSquare />}
                        mr={1}
                    />
                </Tooltip>
                <Tooltip label='Supprimer' placement='top'>
                    <IconButton
                        variant="outline"
                        rounded="full"
                        onClick={() => {
                            setToDeletion(id)
                            onOpen()
                        }}
                        colorScheme='red'
                        aria-label='Delete'
                        icon={<FaTrash />}
                    />
                </Tooltip>
            </>
        } else {
            return null
        }
    }

    const handleDeletion = async () => {
        try {
            const response = await removeQuiz(toDeletion);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                reload()

        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression du questionnaire',
                status: 'error',
            })
        }
    }

    return paginationData.length === 0 ? <Flex justifyContent={"center"}>
        {loading ?
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr userSelect={"none"}>
                            <Th onClick={() => requestSort('name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nom</Text>{getSortDirection('name')}
                                </Flex>
                            </Th>
                            <Th>Description</Th>
                            <Th onClick={() => requestSort('team')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Équipe</Text>{getSortDirection('team')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('permissions')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Permission</Text>{getSortDirection('permissions')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('questions.length')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nombre de questions</Text>{getSortDirection('questions.length')}
                                </Flex>
                            </Th>
                            <Th>Compétences</Th>
                            <Th onClick={() => requestSort('type')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Type</Text>{getSortDirection('type')}
                                </Flex>
                            </Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td colSpan={7} textAlign={"center"}>
                                <Spinner />
                                <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Import des questionnaires en cours...</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            :
            <Box p={4}>
                <Image src={EmptyQuizzes} alt="Aucun questionnaires" maxHeight={500} />
                <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos questionnaires apparaîtrons ici !</Text>
            </Box>
        }
    </Flex>
        :
        <>
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr userSelect={"none"}>
                            <Th onClick={() => requestSort('name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nom</Text>{getSortDirection('name')}
                                </Flex>
                            </Th>
                            <Th>Description</Th>
                            <Th onClick={() => requestSort('teamOwner.name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Équipe</Text>{getSortDirection('teamOwner.name')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('permissions')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Permission</Text>{getSortDirection('permissions')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('questions.length')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Nombre de questions</Text>{getSortDirection('questions.length')}
                                </Flex>
                            </Th>
                            <Th>Compétences</Th>
                            <Th onClick={() => requestSort('type')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Type</Text>{getSortDirection('type')}
                                </Flex>
                            </Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {loading ?
                            <Tr>
                                <Td colSpan={7} textAlign={"center"}>
                                    <Spinner />
                                    <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Import des questionnaires en cours...</Text>
                                </Td>
                            </Tr>
                            :
                            paginationData.map((quiz, index) => (
                                <Tr key={index} cursor={"pointer"} onClick={() => navigate(`/questionnaire/${quiz._id}`)} _hover={{ bg: "gray.100" }} >
                                    <Td>{quiz.name}</Td>
                                    <Td>{quiz.description}</Td>
                                    <Td>{quiz.teamOwner.name}</Td>
                                    <Td>{convertPermissionToLabel(quiz.permissions)}</Td>
                                    <Td>{quiz.questions.length}</Td>
                                    <Td>{getBadgeFromTags(quiz.tags)}</Td>
                                    <Td>{convertTypeToLabel(quiz.type)}</Td>
                                    <Td>{getActionsFromPermission(quiz.permissions, quiz._id)}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Box onClick={scrollToTop}>
                <PaginationTable pageSize={PAGINATION_SIZE} pageIndex={pageIndex} setPageIndex={setPageIndex} totalItemsCount={sortedQuizzes.length} showQuantity={true} />
            </Box>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le questionnaire <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
}

export default QuizzesTable