import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);  // Start the element 50px above its original position
  }
  to {
    opacity: 1;
    transform: translateY(0);      // Element returns to its original position
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);  // Start the element 50px above its original position
  }
  to {
    opacity: 1;
    transform: translateY(0);      // Element returns to its original position
  }
`;

export { fadeIn, slideDown, slideUp };