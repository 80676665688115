import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, VStack, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvitationById } from "../API/Invitations";
import backgroundImage from "../Resources/Images/register_illustration.jpg";
import HorizontalLogo from "../Resources/Logos/HoritzontalLogoTransparency.png";
import { handleFormErrors } from "../Utils/HandleFormErrors";
import { register } from "../API/Users";

const CandidateRegisterForm = () => {
    const { invitationId } = useParams()
    const [errors, setErrors] = useState({})
    const [infos, setInfos] = useState({ firstname: '', lastname: '', phone: '', password: '', confirmPassword: '' })
    const [team, setTeam] = useState('')
    const [email, setEmail] = useState('')
    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        getInvitationById(invitationId).then(res => res.json()).then((data) => {
            if (data.message) {
                toast({
                    title: data.message,
                    status: "error"
                })
                return navigate("/")
            }
            if (data.errors) {
                toast({
                    title: data.errors[0].msg,
                    status: "error",
                })
                return navigate("/")
            }
            setTeam(data.team)
            setEmail(data.email)
        })
    }, [])

    const handleInfosChange = (field, value) => {
        setInfos({ ...infos, [field]: value })
    }

    const handleSubmit = async () => {
        const { firstname, lastname, phone, password, confirmPassword } = infos;
        try {
            const response = await register({ firstname, lastname, phone, password, confirmPassword, status: "Nouveau" }, invitationId);

            if (response.ok) {
                navigate("/");
                toast({
                    title: 'Inscription reussie.',
                    description: 'Veuillez vous connecter.'
                })
            } else {
                const data = await response.json();
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }

                toast({
                    title: 'Erreur.',
                    description: data.msg ?? "Veuillez corriger les erreurs ci-dessus.",
                    status: 'error',
                })
            }
        } catch (error) {
            toast({
                title: 'Une erreur est survenue. Merci de réesayer plus tard.',
                status: 'error',
            })
        }
    }

    return (
        <Flex>
            <Flex flex={5} direction={"column"}>
                <Box p={4}>
                    <img width={300} src={HorizontalLogo} alt="Aptitude logo horizontal" />
                </Box>
                <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                    <Box width={500}>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <Flex gap={4}>
                                <FormControl isInvalid={errors && errors.lastname}>
                                    <FormLabel>Nom</FormLabel>
                                    <Input type='text' value={infos.lastname} onChange={(e) => handleInfosChange('lastname', e.target.value)} placeholder="Renseignez votre nom" />
                                    {errors && errors.lastname && <FormErrorMessage>{errors.lastname}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={errors && errors.firstname}>
                                    <FormLabel>Prénom</FormLabel>
                                    <Input type='text' value={infos.firstname} onChange={(e) => handleInfosChange('firstname', e.target.value)} placeholder="Renseignez votre prénom" />
                                    {errors && errors.firstname && <FormErrorMessage>{errors.firstname}</FormErrorMessage>}
                                </FormControl >
                            </Flex>

                            <FormControl>
                                <FormLabel>Équipe</FormLabel>
                                <Input type='text' value={team} disabled={true} />
                            </FormControl >
                            <FormControl>
                                <FormLabel>Adresse email</FormLabel>
                                <Input type='email' value={email} disabled={true} />
                            </FormControl >
                            <FormControl>
                                <FormLabel>Numéro de téléphone</FormLabel>
                                <Input type='text' value={infos.phone} onChange={(e) => handleInfosChange('phone', e.target.value)} placeholder="06 35 72 83 25" />
                            </FormControl >
                            <FormControl isInvalid={errors && errors.password}>
                                <FormLabel>Mot de passe</FormLabel>
                                <Input type='password' value={infos.password} onChange={(e) => handleInfosChange('password', e.target.value)} placeholder="Renseignez votre mot de passe" />
                                {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                            </FormControl >

                            <FormControl isInvalid={errors && errors.confirmPassword}>
                                <FormLabel>Confirmation du mot de passe</FormLabel>
                                <Input type='password' value={infos.confirmPassword} onChange={(e) => handleInfosChange('confirmPassword', e.target.value)} placeholder="Renseignez votre mot de passe" />
                                {errors && errors.confirmPassword && <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>}
                            </FormControl >
                            <Button
                                mt={4}
                                colorScheme='brand'
                                onClick={handleSubmit}
                                mb={0}
                            >
                                Rejoindre l'équipe
                            </Button>
                        </VStack>
                        <Text fontSize='xs' mt={2}>Pas encore de compte recruteur ?</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box flex={2} height={"100vh"} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "bottom" }}>

            </Box>
        </Flex>
    )
}

export default CandidateRegisterForm