import { Avatar, Center, Flex, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StatusBadge from '../../Utils/StatusBadge'
import HomepageEmptyTable from '../HomepageEmptyTable'
import { fadeIn } from './Animation'

const ListCandidate = ({ teams }) => {
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(teams)) {
            setIsLoading(false);
        }
    }, [teams]);

    if (isLoading) {
        return (
            <Center flex={1}>
                <Spinner size="xl" color="teal.500" />
            </Center>
        );
    }

    const hasCandidates = Array.isArray(teams) && teams.some(team => Array.isArray(team.candidate) && team.candidate.length > 0);

    return (
        <Flex
            flex={1}
            gridArea={"2 / 1 / 4 / 3"}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            animation={`${fadeIn} .4s ease-in`}
        >
            {hasCandidates ? (
                <TableContainer flex={1} mt={4} overflowX={{ base: "scroll", md: "auto" }}>
                    <Table variant='unstyled' size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th color={"gray.500"} pb={4} width={"10px"} />
                                <Th color={"gray.500"} pb={4}>Nom</Th>
                                <Th color={"gray.500"} pb={4}>Prénom</Th>
                                <Th color={"gray.500"} pb={4}>Email</Th>
                                <Th color={"gray.500"} pb={4}>Statut</Th>
                            </Tr>
                        </Thead>
                        <Tbody borderTop="1px solid #E2E8F0">
                            {teams.map(team => (
                                <React.Fragment key={'team-' + team._id}>
                                    {Array.isArray(team.candidate) && team.candidate.map((candidate, index) => (
                                        <Tr key={'candidate-' + index} color={"gray.700"} fontWeight={"semibold"} cursor={"pointer"} _hover={{ bg: "gray.100" }} onClick={() => { navigate('/candidat/' + candidate._id) }}>
                                            <Td width={"10px"}>
                                                <Avatar size='sm' name={candidate.firstname + ' ' + candidate.lastname} src={candidate.blob} />
                                            </Td>
                                            <Td>{candidate.lastname}</Td>
                                            <Td>{candidate.firstname}</Td>
                                            <Td>{candidate.email}</Td>
                                            <Td> <StatusBadge status={candidate.status} /> </Td>
                                        </Tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : <HomepageEmptyTable text={"Vous n'avez aucun candidat pour le moment !"} />}
        </Flex>
    );
};

export default ListCandidate;