const CopyToClipboard = (stringToCopy) => {

    return navigator.clipboard.writeText(stringToCopy)
        .then(() => {
            return true
        })
        .catch((error) => {
            return false
        });
}

export default CopyToClipboard