import React from 'react'
import { Checkbox, Code, Flex, Text } from '@chakra-ui/react'
export default function CheckboxComponent({ responses, responsesChecked, handleChange, questionIndex }) {
    return (
        <>
            {responses.map((res, index) => <Flex key={index} gap={4}>
                <Checkbox checked={responsesChecked[index]} onChange={(e) => handleChange(questionIndex, index, e.target.checked)} />
                <Text key={index} fontSize='md'><Code>{res}</Code></Text>
            </Flex>)}
        </>

    )
}
