import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { TINY_MCE_KEY } from '../keys';

const CreateJobOfferWysiwygComponent = ({ handleEditorChange, defaultValue }) => {
  return (
    <Editor
      apiKey={TINY_MCE_KEY}
      value={defaultValue}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'lists'
        ],
        toolbar: 'undo redo | fontsizeinput | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        inline_styles: true,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',

      }}

      onEditorChange={handleEditorChange}
    />
  );
};

export default CreateJobOfferWysiwygComponent;