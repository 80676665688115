import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Flex, Heading, Text, Button, useToast } from '@chakra-ui/react';
import { applyInvitation, denyInvitation, getInvitationByIdLogged } from '../API/Invitations';

const JoinTeamComponent = () => {
    const { invitationId } = useParams();
    const [invitation, setInvitation] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const fetchInvitation = async () => {
            setLoading(true);
            try {
                const response = await getInvitationByIdLogged(invitationId);
                const data = await response.json();
                if (response.ok) {
                    setInvitation(data);
                } else {
                    setError(data.message);
                }
            } catch (error) {
                toast({
                    title: 'Une erreur est survenue',
                    description: error.message,
                    status: 'error',
                })
                navigate("/");
            } finally {
                setLoading(false);
            }
        };
        fetchInvitation();
    }, [invitationId]);

    const acceptInvitation = async () => {
        setLoading(true);
        try {
            const response = await applyInvitation(invitationId);

            const data = await response.json();

            if (!response.ok) {
                toast({
                    title: 'Une erreur est survenue',
                    description: data.message,
                    status: 'error',
                })
            } else {
                toast({
                    title: 'Invitation acceptee',
                    description: 'L\'invitation a bien(et) acceptee',
                });
            }
        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            })
        } finally {
            setLoading(false);
            navigate('/');
        }
    };

    const refuseInvitation = async () => {
        setLoading(true);
        try {
            const response = await denyInvitation(invitationId);

            if (!response.ok) {
                toast({
                    title: 'Une erreur est survenue',
                    description: error.message,
                    status: 'error',
                })
            } else {
                toast({
                    title: 'Invitation refusee',
                    description: 'L\'invitation a bien été refusée',
                    status: 'success',
                });
            }

        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            })
        } finally {
            setLoading(false);
            navigate('/');
        }
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!invitation) {
        return <div>L'invitation n'existe pas</div>;
    }

    return (
        <Container maxW="md">
            <Heading as="h2" size="xl" mt={4}>
                Invitation pour rejoindre l'équipe {invitation.team.name}
            </Heading>
            <Text mt={4}>
                Vous avez reçu une invitation pour rejoindre cette équipe en tant que  {invitation.role === 'candidate' ? "candidat" : "recruteur"}
            </Text>
            <Flex justifyContent="space-between" mt={8}>
                <Button
                    colorScheme="red"
                    onClick={refuseInvitation}
                    isLoading={loading}
                >
                    Refuser
                </Button>
                <Button
                    colorScheme="green"
                    onClick={acceptInvitation}
                    isLoading={loading}
                >
                    Accepter
                </Button>
            </Flex>
        </Container>
    );
};

export default JoinTeamComponent;