import React from 'react'
import { Box, Image, Flex } from '@chakra-ui/react'
import HoritzontalLogo from '../Resources/Logos/HoritzontalLogoTransparency.png'

const PhoneHeader = () => {
    return (
        <Flex align="center" w="100%" h="75px" pl={4}>
            <Image src={HoritzontalLogo} h="50%" />
        </Flex >
    )
}

export default PhoneHeader