import { Box, Button, Card, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, FormControl, FormErrorMessage, Flex, Heading, IconButton, Input, Table, TableContainer, Tag, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr, useToast } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import { FaKey, FaTrash } from 'react-icons/fa6';
import { addMemberToTeam, getTeamById, promoteMemberById, removeMember, updateTeam } from '../API/Teams';
import { handleFormErrors } from "../Utils/HandleFormErrors";
import CopyToClipboard from '../Utils/CopyToClipboard';
import { UserContext } from '../Authentication/ProtectedRoute';

const ProfileUpdateTeam = ({ isVisible, onClose, team, role }) => {
    const [teamInformations, setTeamInformations] = useState(null);
    const [memberInput, setMemberInput] = useState("")
    const [isUpdate, setIsUpdate] = useState(false)
    const [errors, setErrors] = useState({})
    const { loadData } = useContext(UserContext);
    const toast = useToast();

    const loadTeamInformations = async () => {
        try {
            const response = await getTeamById(team.id)

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setTeamInformations(data)
        } catch (error) {
            toast({
                title: "Impossible de charger les informations de l'équipe",
                status: "error",
            })
        }
    }

    const addMember = async (memberId) => {
        try {
            const response = await addMemberToTeam(team.id, memberId);
            const data = await response.json();

            if (!response.ok) {
                handleFormErrors(setErrors, data.errors);

                throw new Error('Failed to fetch data');
            }

            toast({
                title: "Membre ajouté"
            })
            setMemberInput("");
            loadTeamInformations();
            loadData();
        } catch (error) {
            toast({
                title: "Impossible d'ajouter le membre",
                status: "error",
            })
        }
    }

    const promoteMember = async (memberId) => {
        try {
            const response = await promoteMemberById(team.id, memberId);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            toast({
                title: "Membre promu"
            })
            loadTeamInformations();
            loadData();
        } catch (error) {
            toast({
                title: "Impossible de promouvoir le membre",
                status: "error",
            })
        }
    }

    const deleteMember = async (memberId) => {
        try {
            const response = await removeMember(team.id, memberId);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            toast({
                title: "Membre supprimé"
            })
            loadTeamInformations();
            loadData();
        } catch (error) {
            toast({
                title: "Impossible de supprimer le membre",
                status: "error",
            })
        }
    }

    const udapteTeamInformations = async () => {
        try {
            const response = await updateTeam(team.id, { ...teamInformations });
            const data = await response.json();

            if (!response.ok) {
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }
                throw new Error('Failed to fetch data');
            }
            setErrors({});
            setIsUpdate(false);
            toast({
                title: "Informations mises à jour"
            })
            loadTeamInformations();
            loadData();
        } catch (error) {
            toast({
                title: "Impossible de mettre à jour les informations de l'équipe",
                status: "error",
            })
        }
    }

    useEffect(() => {
        if (team !== null) {
            loadTeamInformations()
        }

    }, [team])

    const getTagByRole = (role) => {
        if (role === "member") return "Membre"
        else if (role === "administrator") return "Administrateur"
        else if (role === "candidate") return "Candidat"
    }

    if (!team || !teamInformations) return null

    return (
        <Drawer
            isOpen={isVisible}
            placement='right'
            onClose={onClose}
            size={"lg"}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    Informations
                </DrawerHeader>
                <DrawerBody p={2} display={"flex"} flexDirection={"column"} gap={2}>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Flex justifyContent={"space-between"} >
                                    <Box>
                                        <Tag mb={4} cursor={"pointer"} fontSize='xs' onClick={async () => {
                                            let status = await CopyToClipboard(teamInformations._id)
                                            if (status) {
                                                toast({
                                                    title: 'Presse papier.',
                                                    description: `L'identifiant de votre équipe ${teamInformations.name} a bien été copié.`,
                                                })
                                            } else {
                                                toast({
                                                    title: 'Presse papier.',
                                                    description: `L'identifiant de votre équipe ${teamInformations.name} n'a pas pu être copié.`,
                                                    status: 'error',
                                                })
                                            }
                                        }}>
                                            {teamInformations._id}
                                        </Tag>
                                    </Box>
                                    <Box>
                                        <Button size="sm" onClick={() => setIsUpdate(!isUpdate)}>{isUpdate ? "Passer en mode lecture" : "Passer en mode édition"}</Button>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box>
                                <Heading size='xs' textTransform='uppercase' pb={0}>
                                    Nom de l'équipe
                                </Heading>
                                {isUpdate ?
                                    <FormControl isInvalid={errors && errors.name} >
                                        <Input mt={1} size={"sm"} value={teamInformations.name} onChange={e => setTeamInformations({ ...teamInformations, name: e.target.value })} />
                                        {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                                    </FormControl>
                                    :
                                    <Text fontSize='sm'>
                                        {teamInformations && teamInformations.name}
                                    </Text>
                                }
                            </Box>
                            <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                    Nom de l'entreprise
                                </Heading>
                                {isUpdate ?
                                    <FormControl isInvalid={errors && errors.companyName}>
                                        <Input mt={1} size={"sm"} value={teamInformations.companyName} onChange={e => setTeamInformations({ ...teamInformations, companyName: e.target.value })} />
                                        {errors && errors.companyName && <FormErrorMessage>{errors.companyName}</FormErrorMessage>}
                                    </FormControl>
                                    :
                                    <Text fontSize='sm'>
                                        {teamInformations && teamInformations.companyName}
                                    </Text>
                                }
                            </Box>
                            <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                    Description
                                </Heading>
                                {isUpdate ?
                                    <FormControl isInvalid={errors && errors.description}>
                                        <Textarea mt={1} size={"sm"} value={teamInformations.description} onChange={e => setTeamInformations({ ...teamInformations, description: e.target.value })} />
                                        {errors && errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                                    </FormControl>
                                    :
                                    <Text fontSize='sm'>
                                        {teamInformations && teamInformations.description}
                                    </Text>
                                }
                            </Box>
                            {isUpdate ? <Box textAlign={'right'}>
                                <Button colorScheme='brand' size="sm" onClick={() => udapteTeamInformations()}>Enregistrer</Button>
                            </Box> : null}
                            <Box>
                                <Heading size='xs' textTransform='uppercase' mb={2}>
                                    Votre rôle dans l'équipe
                                </Heading>
                                <Text fontSize='sm'>
                                    <Tag bg={team.role === "administrator" ? 'gray.600' : 'blue.400'} color="white">{getTagByRole(team.role)}</Tag>
                                </Text>
                            </Box>
                        </Flex>
                    </Card>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Heading size='xs' textTransform='uppercase' pb={4}>
                                    Administrateur
                                </Heading>
                                <TableContainer>
                                    <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {teamInformations && teamInformations.administrator.map((administrator, index) => <Tr key={index}>
                                                <Td>{administrator.lastname}</Td>
                                                <Td>{administrator.firstname}</Td>
                                                <Td>{administrator.email}</Td>
                                            </Tr>)}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Flex>
                    </Card>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Heading size='xs' textTransform='uppercase' pb={4}>
                                    Membre
                                </Heading>
                                <TableContainer>
                                    <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>

                                                {isUpdate ? <Th>Action</Th> : null}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {teamInformations && teamInformations.member.map((member, index) => <Tr key={index}>
                                                <Td>{member.lastname}</Td>
                                                <Td>{member.firstname}</Td>
                                                <Td>{member.email}</Td>
                                                {isUpdate ? <Td display={"flex"} gap={1}>
                                                    <Tooltip label="Promouvoir en tant qu'administrateur">
                                                        <IconButton rounded="full" size={"xs"} icon={<FaKey />} colorScheme='brand' onClick={() => promoteMember(member._id)} />
                                                    </Tooltip>
                                                    <Tooltip label="Supprimer le candidat de l'équipe">
                                                        <IconButton rounded="full" size={"xs"} icon={<FaTrash />} colorScheme='red' onClick={() => deleteMember(member._id)} />
                                                    </Tooltip>
                                                </Td> : null}
                                            </Tr>)}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            {isUpdate ?
                                <FormControl isInvalid={errors && errors.userId}>
                                    <Box display="flex" gap={1}>
                                        <Input value={memberInput} onChange={e => setMemberInput(e.target.value)} placeholder='UUID utilisateur' size='sm' flex={3} />
                                        <Button onClick={() => addMember(memberInput)} flex={1} size="sm">Ajouter le membre</Button>
                                    </Box>
                                    {errors && errors.userId && <FormErrorMessage>{errors.userId}</FormErrorMessage>}
                                </FormControl>
                                : null
                            }
                        </Flex>
                    </Card>

                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ProfileUpdateTeam