import React, { useState, useEffect } from 'react';
import { Image, useToast } from '@chakra-ui/react'; // Ou selon ton UI framework
import getImageFromS3 from "../Utils/ImageFromS3";

const QuestionImage = ({ question }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const toast = useToast();

    const downloadPictureUrl = async (questionimage) => {
        const pictureBlob = await getImageFromS3(questionimage)
        if (!pictureBlob) {
            return
        }
        const url = URL.createObjectURL(pictureBlob)
        return url
    }


    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (question.image && !question.tmp) {
                    const url = await downloadPictureUrl(question.image);
                    setImageUrl(url); // Mettre à jour l'état avec l'URL de l'image
                    return;
                }
                setImageUrl(null);
            } catch (error) {
                setImageUrl(null);
                toast({
                    title: 'Erreur lors du téléchargement de l\'image',
                    status: 'error',
                })
            }
        };

        fetchImage();
    }, [question.image, question.tmp]); // L'effet se déclenche quand `question.image` ou `question.tmp` change

    return (
        <>
            {imageUrl && !question.tmp && (
                <Image
                    width={"100%"}
                    maxHeight={"500px"}
                    objectFit="contain"
                    src={imageUrl} // Utiliser l'URL récupérée
                    mb={4}
                />
            )}
            {question.image && question.tmp && <Image width={"100%"} maxHeight={"500px"} objectFit="contain" src={question.tmp} mb={4} />}
        </>
    );
};

export default QuestionImage;
