if (process.env.NODE_ENV === "production") {
    module.exports = {
        API_URL: "https://app.aptitude-recrutement.fr/api",
        GOOGLE_MAPS_KEY: "AIzaSyCSLCnLOMTqr756L7bY1PCfbmMRE6C66UA",
        TINY_MCE_KEY: "ncbe553vnl3y8worxq9kvsmdg3tqw5t0vt28s6xqwqtvhn8j"
    }
} else {
    // TODO: Avoir une key de dev et une key de prod
    module.exports = {
        API_URL: "http://localhost:4500",
        GOOGLE_MAPS_KEY: "AIzaSyCSLCnLOMTqr756L7bY1PCfbmMRE6C66UA",
        TINY_MCE_KEY: "ncbe553vnl3y8worxq9kvsmdg3tqw5t0vt28s6xqwqtvhn8j"
    }
}
