import csrfFetch from '../Utils/CSRF';

export const createNote = async (noteToUpdate, candidat, title, note, teamId, method) => {
    const url = `/notes/${noteToUpdate && noteToUpdate._id ? noteToUpdate._id : ''}`;

    const response = await csrfFetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            teamId,
            candidateId: candidat._id,
            description: note,
            title
        })
    });

    return response;
}

export const removeNote = async (noteId) => {
    return await csrfFetch(`/notes/${noteId}`, {
        method: 'DELETE'
    });
}
