import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, useToast } from '@chakra-ui/react'
import { deleteAttachment } from "../../../API/Attachements";

const DeleteAttachmentDialog = ({ isOpen, cancelRef, onClose, attachment }) => {
    const toast = useToast();

    const handleSubmit = async () => {

        const response = await deleteAttachment(attachment._id)

        if (!response.ok) {
            const error = await response.json();
            toast({
                title: "Suppression",
                description: "La pièce jointe n'a pas été supprimée",
                status: "error"
            })
            throw new Error(error.message);
        }


        toast({
            title: "Suppression",
            description: "La pièce jointe a été supprimée",
        })

        onClose()
    }

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                setFiles(null)
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Êtes-vous sur de vouloir supprimer cette pièce jointe ?
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Voulez supprimer la pièce jointe <b>{attachment && attachment.name}</b>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Flex gap={2}>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' ref={cancelRef} onClick={handleSubmit}>
                                Supprimer
                            </Button>
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default DeleteAttachmentDialog
