import React from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';

const mimeTypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/webp',
    'application/pdf',
    'text/plain',
    'video/mp4',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const AttachmentFileUploader = ({ handleChange, isInvalid }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            border={isInvalid ? "2px dashed red" : "2px dashed #319795"}
            borderRadius="6px"
            height="200px"
            width="100%"
            position="relative"
            p={4}
            _hover={{ borderColor: 'teal.600' }}
        >
            <Input
                type="file"
                accept={mimeTypes}
                onChange={e => handleChange(e.target.files)}
                multiple
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                opacity="0"
                cursor="pointer"
                zIndex="1"
            />

            <Box zIndex="0" textAlign="center">
                <Text fontSize="md" color="gray.500">
                    Glisser-déposer ou cliquer pour téléverser une pièce jointe
                </Text>
                <Button mt={2} size="sm">
                    Choisir un fichier
                </Button>
            </Box>
        </Box>
    );
};

export default AttachmentFileUploader;