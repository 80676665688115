import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Divider, Input, Text, Tooltip, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FaTrash } from 'react-icons/fa6'

const CandidatAddQuiz = ({ isVisible, quizzes, cancel, updateRunningQuiz, runningQuiz, reload }) => {
    if (!isVisible) return null

    const [filteredQuizzes, setFilteredQuizzes] = useState(quizzes);

    const updateQuiz = async (quizId) => {
        await updateRunningQuiz(quizId);
        await reload();
    }

    return (
        <AlertDialog
            isOpen={isVisible}
            onClose={() => cancel()}
            size={"xl"}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontWeight='bold'>
                        Ajouter un questionnaire
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Box mb='4'>
                            <Input
                                variant='flushed'
                                placeholder="Chercher un questionnaire..."
                                onChange={(e) => {
                                    let filteredQuizzes = quizzes.filter(quiz => quiz.name.toLowerCase().includes(e.target.value.toLowerCase()));
                                    setFilteredQuizzes(filteredQuizzes);
                                }}
                            />
                        </Box>
                        {filteredQuizzes.length > 0 ? filteredQuizzes.map((quiz, index) => {
                            const isAlreadyRunning = runningQuiz?.some(rquizz => rquizz.quiz._id == quiz._id) ?? false;

                            return <React.Fragment key={index}>
                                <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Tooltip label={quiz.name}>
                                        <Text fontSize='sm' noOfLines={1} overflow='hidden' textOverflow='ellipsis'>
                                            {quiz.name.length > 50 ? `${quiz.name.slice(0, 47)}...` : quiz.name}
                                        </Text>
                                    </Tooltip>
                                    {isAlreadyRunning ? <Button rightIcon={<FaTrash />} size={"sm"} variant={"ghost"} color={"red"} onClick={() => updateQuiz(quiz._id)}>
                                        Supprimer le questionnaire
                                    </Button> : <Button rightIcon={<FaPlus />} size={"sm"} variant={"ghost"} color={"brand"} onClick={() => updateQuiz(quiz._id)}>
                                        Ajouter le questionnaire
                                    </Button>}
                                </Box>
                                <Divider />
                            </ React.Fragment>
                        }) : <Box textAlign='center' py='6'>
                            <Text fontSize='sm' color='gray.600'>
                                Aucun questionnaire correspondant
                            </Text>
                        </Box>}

                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={() => cancel()} ml={3}>
                            Fermer
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default CandidatAddQuiz