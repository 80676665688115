import csrfFetch from "../Utils/CSRF";

export const getDoneQuizByCandidate = async (candidateId, setData) => {
    const response = await csrfFetch(`/done-quiz/${candidateId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }
    
    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    } else if (response.status === 204) {
        setData([]);
    }
}

export const createDoneQuiz = async (quiz) => {
    return await csrfFetch(`/done-quiz/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(quiz)
    });
}

export const getResponse = async (id, setData) => {
    const response = await csrfFetch(`/done-quiz/responses/${id}`);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }

    if (response.status === 200) {
        const { candidateQuiz } = await response.json();
        setData(candidateQuiz);
    } else if (response.status === 204) {
        setData([]);
    }
}
