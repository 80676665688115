import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { fadeIn } from './Animation'

const DashboardStatistic = ({ number, text, color, isMobile }) => {
    return (
        <Flex direction={"column"} boxShadow={""} p={isMobile ? 2 : 4} background={"white"} flex={1} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`} backgroundColor={color} >
            <Text fontSize={isMobile ? "xs" : "sm"} fontWeight={"semibold"} color={ color === "brand.500" ? "white": "gray.700" }>{text}</Text>
            <Text fontSize={isMobile ? 24 : 40} fontWeight={"bold"} color={ color === "brand.500" ? "white": "gray.700" }>{number}</Text>
        </Flex>
    )
}

export default DashboardStatistic
