import { useState, useMemo } from 'react';
import { FaMinus, FaCaretUp, FaCaretDown } from "react-icons/fa6";
export const useSortableData = (items) => {
    const [sortConfig, setSortConfig] = useState({});

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (Object.keys(sortConfig).length === 0) return sortableItems;

        if (sortConfig?.key.includes('.')) {
            const keyArray = sortConfig.key.split('.');
            sortableItems.sort((a, b) => {
                let aValue = a;
                let bValue = b;

                for (const keyPart of keyArray) {
                    aValue = aValue[keyPart];
                    bValue = bValue[keyPart];
                }

                return sortConfig.direction === 'asc'
                    ? aValue.toString().localeCompare(bValue.toString())
                    : bValue.toString().localeCompare(aValue.toString());
            });
        } else {
            sortableItems.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                return sortConfig.direction === 'asc'
                    ? aValue?.toString().localeCompare(bValue?.toString())
                    : bValue?.toString().localeCompare(aValue?.toString());
            });
        }

        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            setSortConfig({ key, direction: 'desc' });
            return;
        } else if (sortConfig.key === key && sortConfig.direction === undefined || !sortConfig.key) {
            setSortConfig({ key, direction: 'asc' });
            return;
        }

        setSortConfig({});
    };

    const getSortDirection = (key) => {
        return sortConfig?.key === key ? (sortConfig.direction === 'asc' ? <FaCaretDown /> : <FaCaretUp />) : <FaMinus />;
    };

    return { items: sortedItems, requestSort, getSortDirection };
};
