import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, IconButton, Tag, TagLabel, TagLeftIcon, Text, Tooltip, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FaEye, FaPenToSquare, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { removeQuiz } from '../API/Quizzes';
import { convertTypeToLabel } from '../Utils/QuizzesUtils';

const QuizHeader = ({ _id, name, description, tags, type, accessTeam, modifyTeam, teamOwner, permissions }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const [toDeletion, setToDeletion] = useState(null);
    const [isTabletOrMobile] = useMediaQuery('(max-width: 855px)');


    const handleDeletion = async () => {
        try {
            const response = await removeQuiz(toDeletion);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                navigate('/questionnaires');

        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression du questionnaire',
                status: 'error',
            });
        }
    };

    return (
        <Box w='100%' pt={2} color="gray.700" bg="white" borderRadius="xl" p={6}>
            {isTabletOrMobile ? (
                <Flex alignItems="left" flexDirection="column">
                    <Tag fontSize='xs' type='whiteAlpha' width="fit-content">{_id}</Tag>
                    <Text as='b' fontSize='4xl'>{name}</Text>
                    <Flex mt={2}>
                        {permissions === 'own' || permissions === 'write' ? (
                            <>
                                <Tooltip label='Modifier' placement='top'>
                                    <IconButton
                                        as={Link}
                                        to={`/questionnaire/update/${_id}`}
                                        size='sm'
                                        aria-label='Update'
                                        colorScheme='brand'
                                        icon={<FaPenToSquare />}
                                        mr={1}
                                        rounded="full"
                                    />
                                </Tooltip>
                                <Tooltip label='Supprimer' placement='top'>
                                    <IconButton
                                        size='sm'
                                        colorScheme='red'
                                        aria-label='Delete'
                                        icon={<FaTrash />}
                                        rounded="full"
                                        onClick={() => {
                                            setToDeletion(_id);
                                            onOpen();
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : null}
                    </Flex>
                </Flex>
            ) : (
                <Flex alignItems="center" gap={4}>
                    <Text as='b' fontSize='4xl'>{name}</Text>
                    <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag>
                    <Flex ml="auto">
                        {permissions === 'own' || permissions === 'write' ? (
                            <>
                                <Tooltip label='Modifier' placement='top'>
                                    <IconButton
                                        as={Link}
                                        to={`/questionnaire/update/${_id}`}
                                        size='sm'
                                        aria-label='Update'
                                        colorScheme='brand'
                                        icon={<FaPenToSquare />}
                                        mr={1}
                                        rounded="full"
                                    />
                                </Tooltip>
                                <Tooltip label='Supprimer' placement='top'>
                                    <IconButton
                                        size='sm'
                                        colorScheme='red'
                                        aria-label='Delete'
                                        icon={<FaTrash />}
                                        rounded="full"
                                        onClick={() => {
                                            setToDeletion(_id);
                                            onOpen();
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : null}
                    </Flex>
                </Flex>
            )}
            <Flex alignItems="baseline" gap={4}>
                <Text fontSize='md'>{description}</Text>
            </Flex>
            <Flex alignItems="baseline" gap={1} mt={4}>
                {tags && tags.map((tag, index) => (
                    <Tag fontSize='xs' key={index} variant='solid' colorScheme="brand">{tag}</Tag>
                ))}
                <Flex ml="auto" gap={1}>
                    <Tooltip label='Visibilité du questionnaire' placement='top'>
                        {convertTypeToLabel(type)}
                    </Tooltip>
                    <Tooltip label="Nombre d'équipe qui ont accès au questionnaire en lecture" placement='auto'>
                        <Tag size={'md'} variant='solid' colorScheme="brand">
                            <TagLeftIcon boxSize='12px' as={FaEye} />
                            <TagLabel>{accessTeam && accessTeam.length}</TagLabel>
                        </Tag>
                    </Tooltip>
                    <Tooltip label="Nombre d'équipe qui ont accès au questionnaire en écriture" placement='auto'>
                        <Tag size={'md'} variant='solid' colorScheme="brand">
                            <TagLeftIcon boxSize='12px' as={FaPenToSquare} />
                            <TagLabel>{modifyTeam && modifyTeam.length}</TagLabel>
                        </Tag>
                    </Tooltip>
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null);
                    onClose();
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le questionnaire <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion();
                                onClose();
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default QuizHeader;