import { Button, Container, Flex, Heading, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { loadQuizById } from "../API/Quizzes";
import QuizHeader from '../Components/QuizHeader';
import QuizzesQuestion from '../Components/QuizzesQuestion';

const QuizComponent = () => {
    const { id } = useParams();
    const [quiz, setQuiz] = useState({})
    const toast = useToast();

    useEffect(() => {
        try {
            loadQuizById(id, setQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement du questionnaire',
                status: 'error'
            })
        }
    }, [])

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button as={Link} to={"/questionnaires"} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux questionnaires</Button>
                </Flex>
                <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Détail du questionnaire</Heading>
                <Flex direction="column" gap={4}>
                    <QuizHeader {...quiz} permissions={quiz.permissions} />
                    {quiz && quiz && quiz.questions &&
                        quiz.questions.map((question, index) => <QuizzesQuestion key={index} {...question} answers={question.acceptedAnswers} />)}
                </Flex>
            </Container>
        </>
    )
}

export default QuizComponent