import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import csrfFetch from '../Utils/CSRF';

const GoogleAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('code')) {
            csrfFetch('/auth/google/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: searchParams.get('code'),
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    toast({
                        title: 'Connexion reussie',
                        description: "Nous avons pu vous connecter à Google.",
                    })
                })
                .catch((error) => {
                    toast({
                        title: 'Connexion échouée',
                        description: "Nous n'avons pas pu vous connecter à Google.",
                        status: 'error',
                    })
                })
                .finally(() => {
                    navigate('/questionnaires')
                });
        }
    })
}

export default GoogleAuth;
