import { Button, Container, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { getResponse } from '../API/DoneQuiz';
import ExamResultHeaderComponent from '../Components/ExamResultHeaderComponent';
import ExamResultQuestionCard from '../Components/ExamResultQuestionCard';

const QuizResultsComponent = () => {
    const { quizId } = useParams();
    const [quiz, setQuiz] = useState(null)


    const fetchExamQuiz = async (id) => {
        try {
            await getResponse(id, setQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération du quiz',
                status: 'error',
            })
        }
    }

    useEffect(() => {
        fetchExamQuiz(quizId);
    }, [])

    useEffect(() => {
    }, [quiz])


    if (!quiz) return null


    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button as={Link} to={`/candidat/${quiz.candidateId}`} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour au candidat</Button>
                </Flex>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={4} color={"gray.700"}>Résultats</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <ExamResultHeaderComponent {...quiz} />
                {quiz.questions.map((question, index) => {
                    return <ExamResultQuestionCard key={index} question={question} questionIndex={index} candidateAnswers={quiz.answers.find(el => el.questionIndex === question.index).list} acceptedAnswers={question.acceptedAnswers} />
                })}
            </Container>
        </>
    )
}

export default QuizResultsComponent