import csrfFetch from '../Utils/CSRF';

export const postJobApplication = async (jobId, data) => {
    const response = await csrfFetch(`/job-applications/${jobId}`, {
        method: 'POST',
        body: data
    });

    return response;
}

export const getCandidateApplicationByJobId = async (jobId) => {
    const response = await csrfFetch(`/job-applications/${jobId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    return await response.json();
};

export const getJobOfferApplications = async (jobId, setData) => {
    const response = await csrfFetch(`/job-applications/team/${jobId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data);
    }
}

export const getJobApplicationForTeam = async (jobId, jobApplicationId, setData) => {
    const response = await csrfFetch(`/job-applications/${jobId}/get/${jobApplicationId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    setData(data);
}

export const changeJobApplicationStatus = async (jobApplicationId, status, setData, toast) => {
    const response = await csrfFetch(`/job-applications/update-status/${jobApplicationId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status })
    });

    if (!response.ok) {
        toast({
            title: "Erreur",
            description: "La modification a été echouée !",
            status: "error"
        });
        throw new Error('Failed to edit attachment');
    }

    const data = await response.json();

    toast({
        title: "Le statut a bien été mis à jour !",
    });

    setData(data)
}