import csrfFetch from "../Utils/CSRF";

export const storeRunningQuiz = async (quizId, candidateId) => {
    return await csrfFetch('/running-quiz/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            quizId,
            candidateId
        })
    });
}

export const getRunningQuizByCandidate = async (candidateId, setData) => {
    const response = await csrfFetch(`/running-quiz/` + candidateId);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    }  else if (response.status === 204) {
        setData([]);
    }
}

export const removeRunningQuiz = async (runningQuizId) => {
    return await csrfFetch(`/running-quiz/` + runningQuizId, {
        method: 'DELETE'
    });
}

export const getRunningQuiz = async (runningQuizId, setData) => {
    const response = await csrfFetch(`/running-quiz/get-exam-quiz/${runningQuizId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch questionnaire');
    }
    if (response.status === 200) {
        const data = await response.json();
        setData(data);
    } else if (response.status === 204) {
        setData([]);
    }
}