import { Flex, Text } from '@chakra-ui/react'
import { FaFileCircleCheck } from "react-icons/fa6";
import React from 'react'

const EmptyDoneQuizCard = ({ isCandidate }) => {
    return (
        <Flex p={4} color={"gray.600"} alignItems="center" gap={4} bg={"white"}>
            <FaFileCircleCheck size={80} color="#03B97C" />
            <Text textAlign="left" wordBreak={"break-all"}>{isCandidate ? "Vous n'avez réalisé aucun questionnaire pour le moment !" : "Aucun questionnaire n'a été réalisé par ce candidat"}</Text>
        </Flex>
    )
}

export default EmptyDoneQuizCard