import { Badge } from "@chakra-ui/react";

const StatusBadge = ({ status }) => {

    const statusMap = {
        "Nouveau": { variant: "outline", colorScheme: "blue" },
        "En cours": { variant: "solid", colorScheme: "yellow" },
        "Retenu": { variant: "solid", colorScheme: "green" },
        "Non retenu": { variant: "solid", colorScheme: "red" },
        "Accepté": { variant: "solid", colorScheme: "green" },
        "Refusé": { variant: "solid", colorScheme: "red" },
        "En attente": { variant: "solid", colorScheme: "yellow" },
    };

    const { variant = "solid", colorScheme = "gray" } = statusMap[status] || {};

    return (
            <Badge variant={variant} colorScheme={colorScheme}>
                {status}
            </Badge>
    );
};

export default StatusBadge;