import React from 'react'
import { Input } from '@chakra-ui/react'

export default function ShortComponent({ responses, responsesChecked, handleChange, questionIndex }) {
    return (
        <>
            <Input onChange={(e) => handleChange(questionIndex, 0, e.target.value, "text")} />
        </>

    )
}
