import React, { useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Box, Button, Checkbox, Container, Flex, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Input, InputGroup, InputRightElement, Radio, RadioGroup, Select, Stack, Tag, TagCloseButton, TagLabel, Text, Textarea, useMediaQuery, useToast } from '@chakra-ui/react';
import { FaArrowLeft, FaPlus, FaSave, FaTrash } from 'react-icons/fa';

import FileUploaderComponent from '../Components/FileUploaderComponent';
import onKeyPressEnter from '../Utils/OnKeyPressEnter';

import { loadQuizById, updateQuiz } from '../API/Quizzes';
import { getTeamById, getTeamByUser } from '../API/Teams';
import { handleFormErrors } from "../Utils/HandleFormErrors";
import QuestionImage from "../Utils/QuestionImage";
import { FaDeleteLeft } from 'react-icons/fa6';

const QuizUpdateComponent = ({ }) => {

    const { id } = useParams();
    const [quiz, setQuiz] = useState(null);
    const [newTag, setNewTag] = useState("")
    const [newAccessTeam, setNewAccessTeam] = useState("")
    const [newModifyTeam, setNewModifyTeam] = useState("")
    const [newAnswer, setNewAnswer] = useState({})
    const [newQuestion, setNewQuestion] = useState("")
    const [teams, setTeams] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const toast = useToast()
    const navigate = useNavigate();
    const showRadio = ['multiple', 'unique'];
    const [isTabletOrMobile] = useMediaQuery('(max-width: 768px)');

    const fetchQuiz = async (quizId) => {
        try {
            if (!id) {
                setQuiz({
                    tags: [],
                    accessTeam: [],
                    modifyTeam: [],
                    questions: [],
                    type: 'public'
                });
                return;
            }

            const data = await loadQuizById(quizId, setQuiz);

            if (data.permissions !== 'own' && data.permissions !== 'write') {
                navigate('/questionnaires');
            }
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement du questionnaire',
                status: 'error'
            })
        }
    };

    const fetchUserTeam = async () => {
        try {
            const data = await getTeamByUser();
            setTeams(data)
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des équipes',
                status: 'error'
            })
        }
    }

    useEffect(() => {
        fetchQuiz(id);
        fetchUserTeam()
    }, [])

    const convertToFormData = (quiz) => {
        const formData = new FormData();

        // Ajouter les champs de base à formData
        formData.append('name', quiz.name);
        formData.append('description', quiz.description);
        formData.append('type', quiz.type);
        if ((quiz._id || id) && id !== undefined) {
            formData.append('_id', quiz._id ?? id);
        }

        (quiz.accessTeam ?? []).forEach(team => {
            formData.append('accessTeam', team);
        });

        (quiz.modifyTeam ?? []).forEach(team => {
            formData.append('modifyTeam', team);
        });

        (quiz.tags ?? []).forEach(tag => {
            formData.append('tags', tag);
        });

        // Ajouter les questions et réponses
        quiz.questions.forEach((question, index) => {
            formData.append(`questions[${index}][_id]`, question._id);
            formData.append(`questions[${index}][description]`, question.description);
            formData.append(`questions[${index}][type]`, question.type);
            formData.append(`questions[${index}][index]`, question.index);
            if (typeof question.image === 'object') {
                formData.append(`questions[${index}][image]`, question.image);
            }

            // Ajouter les réponses
            question.responses.forEach((response, responseIndex) => {
                formData.append(`questions[${index}][responses][${responseIndex}]`, response);
            });


            (question.acceptedAnswers ?? []).forEach((acceptedAnswer, acceptedAnswerIndex) => {
                formData.append(`questions[${index}][acceptedAnswers][${acceptedAnswerIndex}]`, acceptedAnswer);
            });

            // Ajouter l'image si elle est présente
            if (question.image && typeof question.image !== 'string') {
                formData.append(`questions[${index}][image]`, question.image); // Ici, question.image doit être un fichier
            }
        });

        // Ajouter les autres propriétés

        formData.append('teamOwner', quiz.teamOwner ?? teams[0]._id);
        formData.append('permissions', quiz.permissions);

        return formData;
    };

    const handleTeam = async (team, newTeam, setTeam, teamName) => {
        if (newTeam.length <= 0) {
            return;
        }

        try {
            const response = await getTeamById(newTeam);

            if (!response.ok) {
                toast({
                    title: "L'équipe n'existe pas.",
                    status: "error"
                });
                return;
            }

            if (!team.includes(newTeam)) {
                team.push(newTeam)
                handleQuizChanges(teamName, team);
                setTeam("");
            } else {
                toast({
                    title: "L'équipe existe déja.",
                    status: "error"
                });
            }
        } catch (error) {
            toast({
                title: "L'équipe n'existe pas.",
                status: "error"
            });
        }
    }

    const handleNewAccessTeam = async () => {
        let { accessTeam } = quiz;
        await handleTeam(accessTeam, newAccessTeam, setNewAccessTeam, "accessTeam");
    }

    const handleNewModifyTeam = async () => {
        let { modifyTeam } = quiz;
        await handleTeam(modifyTeam, newModifyTeam, setNewModifyTeam, "modifyTeam");
    }

    const handleNewTag = () => {
        let { tags } = quiz;
        if (newTag.length > 0) {
            tags.push(newTag)
            handleQuizChanges("tags", tags);
            setNewTag("");
        }
    }

    const handleQuizChanges = (field, value) => {
        setQuiz({ ...quiz, [field]: value })
    }

    const handleQuestionChanges = (questionIndex, field, value, responseIndex = null) => {
        let { questions } = quiz;

        questions[questionIndex][field] = value;

        if (typeof responseIndex === 'number') {
            let { acceptedAnswers } = questions[questionIndex];
            acceptedAnswers = acceptedAnswers.filter(el => el != responseIndex)
            questions[questionIndex]["acceptedAnswers"] = acceptedAnswers
            return setQuiz({ ...quiz, questions: questions })
        }

        setQuiz({ ...quiz, questions: questions })
    }

    const handleQuestionResponseChanges = (questionIndex, responseIndex, value) => {
        let { questions } = quiz;
        questions[questionIndex]["responses"][responseIndex] = value;

        setQuiz({ ...quiz, questions: questions })
    }

    const handleQuestionAnswersChange = (questionIndex, answerIndex, isChecked) => {
        let { acceptedAnswers } = quiz.questions[questionIndex];
        if (!acceptedAnswers) {
            acceptedAnswers = []
        }

        if (isChecked) {
            if (!acceptedAnswers.includes(answerIndex)) {
                acceptedAnswers.push(answerIndex)
            }
        } else {
            acceptedAnswers = acceptedAnswers.filter(el => el != answerIndex)
        }

        const newQuestions = quiz.questions;
        newQuestions[questionIndex]["acceptedAnswers"] = acceptedAnswers;

        setQuiz({ ...quiz, questions: newQuestions })
    }

    const handleImageChange = (questionIndex, image) => {
        let { questions } = quiz;
        questions[questionIndex]["image"] = image;
        questions[questionIndex]["tmp"] = URL.createObjectURL(image);
        setQuiz({ ...quiz, questions: questions })
    }

    const handleSaveQuiz = async () => {
        if (!quiz._id && quiz.type === "")
            quiz.type = 'public'

        try {
            setLoading(true);
            const formData = convertToFormData(quiz);

            const response = await updateQuiz(formData);

            if (!response.ok) {
                const data = await response.json();
                if (data.errors) {
                    handleFormErrors(setErrors, data.errors);
                }
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            const { updatedQuiz } = await response.json();

            navigate(`/questionnaire/update/${updatedQuiz._id}`);

            toast({
                title: 'Modifications sauvardées',
            })
            setLoading(false);
            setErrors({});
        } catch (error) {
            setLoading(false);

            toast({
                title: 'Modification du questionnaire',
                description: "Le serveur n'a pas réussi à mettre à jour le questionnaire",
                status: 'error',
            })
        }
    }

    const generateUuid = () => {
        const uuid = uuidv4().replace(/-/g, ''); // Remove dashes
        return uuid.slice(0, 24); // Trim to 24 characters
    }

    if (!quiz)
        return "waiting..."

    return (
        <>
            <Container maxW="full">
                <Flex alignItems='center' mb={4}>
                    <Button as={Link} to={"/questionnaires"} p={0} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux questionnaires</Button>
                </Flex>

                <Flex justifyContent={"space-between"} alignItems={isTabletOrMobile ? "left" : "center"} mb={4} direction={isTabletOrMobile ? "column" : "row"} gap={isTabletOrMobile ? 4 : 0}>
                    <Heading p={4} pb={0} size={"md"} color={"gray.600"} fontWeight={"semibold"}>{!id ? "Création " : "Modification"} de questionnaire</Heading>
                    <Flex gap={4} px={8}>
                        {!id ? <Button variant={"ghost"} colorScheme="brand" leftIcon={<FaDeleteLeft />} isLoading={loading} disabled={loading} onClick={() => navigate('/questionnaires')}>Annuler la création</Button> : null}

                        <Button leftIcon={<FaSave />} isLoading={loading} disabled={loading} onClick={handleSaveQuiz}>Enregistrer le questionnaire</Button>
                    </Flex>
                </Flex>
                <Flex direction={{ base: "column", lg: "column", xl: "row" }} gap={4} mt={4} display="grid" gridTemplateColumns={{ base: "1fr", lg: "1fr", xl: "25% 74%" }}>
                    <Flex direction="column" gap={4} bg="white" borderRadius={"xl"} p={8}>
                        <Flex alignItems={"center"} justifyContent={"space-between"}>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Informations générales</Text>
                        </Flex>
                        <FormControl isInvalid={errors && errors.name}>
                            <FormLabel>Nom</FormLabel>
                            <Input type='text' value={quiz.name} onChange={(e) => handleQuizChanges('name', e.target.value)} />
                            {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                        </FormControl>
                        <FormControl isInvalid={errors && errors.description}>
                            <FormLabel>Description</FormLabel>
                            <Textarea height={{ base: 150, md: 215 }} value={quiz.description} onChange={(e) => handleQuizChanges('description', e.target.value)} />
                            {errors && errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                        </FormControl>
                    </Flex>
                    <Flex direction="column" gap={4}>
                        <Flex flex={1} direction="column" gap={4} bg="white" borderRadius={"xl"} p={8}>
                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Configuration</Text>
                            </Flex>
                            <Flex gap={4} direction={{ base: "column", lg: "column", xl: "row" }} flexWrap="wrap">
                                <FormControl flex={1}>
                                    <FormLabel>Visibilité</FormLabel>
                                    <RadioGroup value={quiz.type || 'public'} onChange={(e) => handleQuizChanges('type', e)} >
                                        <Stack spacing={4} direction='row'>
                                            <Radio colorScheme='green' value='public'>
                                                Publique
                                            </Radio>
                                            <Radio colorScheme='green' value='private'>
                                                Privée
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                                <FormControl flex={4}>
                                    <FormLabel>Compétences associées</FormLabel>
                                    <InputGroup mb={4}>
                                        <Input
                                            type={'text'}
                                            placeholder='Javascript, Excel, SCRUM, etc...'
                                            value={newTag}
                                            onChange={(e) => setNewTag(e.target.value)}
                                            onKeyDown={(e) => onKeyPressEnter(e, handleNewTag)}
                                        />
                                        <InputRightElement width='4.5rem' pr={1}>
                                            <Button size='xs' onClick={handleNewTag}>
                                                Ajouter
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex align="center" gap={1}>
                                        {quiz.tags && quiz.tags.map((tag, index) => <Tag
                                            key={index}
                                            variant='solid'
                                            colorScheme='brand'
                                        >
                                            <TagLabel>{tag}</TagLabel>
                                            <TagCloseButton onClick={() => handleQuizChanges("tags", [...quiz.tags.slice(0, index), ...quiz.tags.slice(index + 1)])} />
                                        </Tag>)}
                                    </Flex>
                                </FormControl>
                            </Flex>
                            <FormControl w={{ base: "100%", lg: "100%", xl: "50%" }}>
                                <FormLabel>Équipe propriétaire</FormLabel>
                                <Select disabled={!id} spacing={5} value={quiz.teamOwner || ""} onChange={(e) => handleQuizChanges('teamOwner', e.target.value)}>
                                    {teams && teams.length > 0 && teams.map((team, index) => <option key={"team" + index} value={team._id}>{team.name}</option>)}
                                </Select>
                            </FormControl>
                        </Flex>
                        <Flex flex={1} direction="column" gap={4} bg="white" borderRadius={"xl"} p={8}>
                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Partage</Text>
                            </Flex>
                            <Flex gap={5} justifyContent="flex-start" direction={{ base: "column", lg: "column", xl: "row" }}>
                                <FormControl>
                                    <FormLabel>Équipe en lecture</FormLabel>
                                    <InputGroup mb={4}>
                                        <Input
                                            type={'text'}
                                            value={newAccessTeam}
                                            onChange={(e) => setNewAccessTeam(e.target.value)}
                                            onKeyDown={(e) => onKeyPressEnter(e, handleNewAccessTeam)}
                                        />
                                        <InputRightElement width='4.5rem' pr={1}>
                                            <Button size='xs' onClick={handleNewAccessTeam}>
                                                Ajouter
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex align="center" gap={1}>
                                        {quiz.accessTeam && quiz.accessTeam.map((team, index) => <Tag
                                            key={index}
                                            variant='solid'
                                            colorScheme='brand'
                                        >
                                            <TagLabel>{team}</TagLabel>
                                            <TagCloseButton onClick={() => handleQuizChanges("accessTeam", [...quiz.accessTeam.slice(0, index), ...quiz.accessTeam.slice(index + 1)])} />
                                        </Tag>)}
                                    </Flex>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Équipe en écriture</FormLabel>
                                    <InputGroup mb={4}>
                                        <Input
                                            type={'text'}
                                            value={newModifyTeam}
                                            onChange={(e) => setNewModifyTeam(e.target.value)}
                                            onKeyDown={(e) => onKeyPressEnter(e, handleNewModifyTeam)}

                                        />
                                        <InputRightElement width='4.5rem' pr={1}>
                                            <Button size='xs' onClick={handleNewModifyTeam}>
                                                Ajouter
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex align="center" gap={1}>
                                        {quiz.modifyTeam && quiz.modifyTeam.map((team, index) => <Tag
                                            key={index}
                                            variant='solid'
                                            colorScheme='brand'
                                        >
                                            <TagLabel>{team}</TagLabel>
                                            <TagCloseButton onClick={() => handleQuizChanges("modifyTeam", [...quiz.modifyTeam.slice(0, index), ...quiz.modifyTeam.slice(index + 1)])} />
                                        </Tag>)}
                                    </Flex>
                                </FormControl>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex w="full" gap={4} mt={4} direction="column" bg="white" borderRadius={"xl"} p={8}>
                    <Flex alignItems={"center"} justifyContent={"space-between"}>
                        <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Questions</Text>
                    </Flex>
                    {quiz.questions && quiz.questions.map((question, questionIndex) => (
                        <Box p={4} key={questionIndex}>
                            <FormControl mb={4} isInvalid={errors && errors[`questions[${questionIndex}].description`]}>
                                <FormLabel>Titre</FormLabel>
                                <Flex alignItems="center" gap={4} direction={{ base: "column", md: "row" }}>
                                    <Input type='text' value={question.description} onChange={(e) => handleQuestionChanges(questionIndex, "description", e.target.value)} />
                                    <Button colorScheme='red' variant="ghost" aria-label={"Delete question " + questionIndex} leftIcon={<FaTrash />} onClick={() => {
                                        handleQuizChanges("questions", [...quiz.questions.slice(0, questionIndex), ...quiz.questions.slice(questionIndex + 1)])
                                    }}>Supprimer la question</Button>
                                </Flex>
                                {errors && errors[`questions[${questionIndex}].description`] && <FormErrorMessage>{errors[`questions[${questionIndex}].description`]}</FormErrorMessage>}
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel>Image</FormLabel>
                                <Box width={"auto"} maxHeight={500}>
                                    <QuestionImage question={question} />
                                </Box>
                                <Flex alignItems="center" gap={4}>
                                    <FileUploaderComponent
                                        questionIndex={questionIndex}
                                        handleImageChange={handleImageChange}
                                    />
                                </Flex>
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Type de question</FormLabel>
                                <Flex alignItems="center" gap={4}>
                                    <RadioGroup value={question.type} onChange={(newType) => {
                                        // If type is unique make sure only 1 answer is selected
                                        handleQuestionChanges(questionIndex, "type", newType)
                                    }}>
                                        <Stack spacing={4} direction='row'>
                                            <Radio value='multiple'>
                                                Choix multiple
                                            </Radio>
                                            <Radio value='unique'>Choix unique</Radio>
                                            <Radio value='short'>Réponse courte</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Flex>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Réponses</FormLabel>
                                {showRadio.includes(question.type) && question.responses.map((response, responseIndex) => (
                                    <FormControl display={"flex"} flexDirection={"column"} mb={2} key={responseIndex} isInvalid={errors && errors[`questions[${questionIndex}].responses[${responseIndex}]`]}>
                                        <Flex display={"flex"} gap={4} flex={1}>
                                            <Checkbox isChecked={question.acceptedAnswers && question.acceptedAnswers.includes(responseIndex)} onChange={(e) => handleQuestionAnswersChange(questionIndex, responseIndex, e.target.checked)} />
                                            <Input type='text' value={response}
                                                onChange={(e) => handleQuestionResponseChanges(questionIndex, responseIndex, e.target.value)}
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleQuestionChanges(questionIndex, "responses", [...question.responses.slice(0, responseIndex), ...question.responses.slice(responseIndex + 1)], responseIndex)
                                                    }
                                                }}
                                            />
                                            <IconButton rounded="full" variant="ghost" colorScheme='red' aria-label={"Delete response " + responseIndex} icon={<FaTrash />} onClick={() => {
                                                handleQuestionChanges(questionIndex, "responses", [...question.responses.slice(0, responseIndex), ...question.responses.slice(responseIndex + 1)], responseIndex)
                                            }} />
                                        </Flex>
                                        {errors && errors[`questions[${questionIndex}].responses[${responseIndex}]`] && <FormErrorMessage ml={8}>{errors[`questions[${questionIndex}].responses[${responseIndex}]`]}</FormErrorMessage>}
                                    </FormControl>
                                ))}
                                <Flex mb={1} gap={4}>
                                    {showRadio.includes(question.type) ?
                                        <>
                                            <Input ml={8} type='text' value={newAnswer[question._id] || ""}
                                                onChange={(e) => {
                                                    setNewAnswer({ ...newAnswer, [question._id]: e.target.value })
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        setNewAnswer({ ...newAnswer, [question._id]: "" })
                                                        handleQuestionChanges(questionIndex, "responses", [...question.responses, newAnswer[question._id] || ''])
                                                    }
                                                }}
                                            />
                                            <IconButton rounded="full" aria-label={"Add response " + questionIndex} icon={<FaPlus />} onClick={() => {
                                                setNewAnswer({ ...newAnswer, [question._id]: "" })

                                                handleQuestionChanges(questionIndex, "responses", [...question.responses, newAnswer[question._id] || ''])
                                            }} />
                                        </>
                                        :
                                        <Textarea defaultValue={question.responses[0]} onChange={(e) => {
                                            if (!newAnswer[question._id]) {
                                                newAnswer[question._id] = ""
                                            }
                                            handleQuestionChanges(questionIndex, "responses", [e.target.value])
                                            handleQuestionAnswersChange(questionIndex, 0, true)
                                            setNewAnswer({ [question._id]: e.target.value })
                                        }}>
                                        </Textarea>
                                    }
                                </Flex>
                            </FormControl>
                        </Box>
                    ))
                    }
                    <Flex mb={1} gap={4} direction={{ base: "column", md: "row" }}>
                        <Input flex={3} type='text' value={newQuestion} placeholder='Titre de la nouvelle question'

                            onChange={(e) => {
                                setNewQuestion(e.target.value)
                            }}

                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    handleQuizChanges("responses", quiz.questions.push({ _id: generateUuid(), description: newQuestion, type: "unique", responses: [], index: quiz.questions.length }))
                                    setNewQuestion("")
                                }
                            }}
                        />

                        <Button flex={1} aria-label={"Add question "} leftIcon={<FaPlus />} onClick={() => {
                            handleQuizChanges("responses", quiz.questions.push({ _id: generateUuid(), description: newQuestion, type: "unique", responses: [], index: quiz.questions.length }))
                            setNewQuestion("")
                        }}>
                            Ajouter une question
                        </Button>
                    </Flex>
                </Flex >
            </Container >
        </>
    )
}

export default QuizUpdateComponent