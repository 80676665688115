import csrfFetch from "../Utils/CSRF";

export const addAttachement = async (attachment) => {
    return await csrfFetch('/attachments/', {
        method: 'POST',
        body: attachment
    });
}

export const editAttachement = async (attachmentToUpdate) => {
    return await csrfFetch(`/attachments/${attachmentToUpdate._id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(attachmentToUpdate)
    });
}

export const getAttachment = async (key) => {
    return await csrfFetch('/s3/get', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}

export const deleteAttachment = async (id) => {
    return await csrfFetch(`/attachments/${id}`, {
        method: "DELETE"
    })
}

export const getAttachmentsByCandidate = async (candidateId, setData) => {
    const response = await csrfFetch(`/attachments/${candidateId}`);

    if (!response.ok) {
        throw new Error('Failed to fetch attachments');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    }
}