import { useContext } from 'react';
import { UserContext } from '../Authentication/ProtectedRoute';
import CandidatHomepage from './CandidatInterface/CandidatHomepage';
import Homepage from './Dashboard';

const LoggedHomepage = () => {
    const { user } = useContext(UserContext);
    if (user.email === undefined) {
        return <></>;
    }

    if (user.role === 'candidate') {
        return <CandidatHomepage />;
    } else {
        return <Homepage />;
    }
};

export default LoggedHomepage;
