import { FormLabel, Flex, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Divider, FormControl, Input, useToast, Select } from '@chakra-ui/react'
import React, { useState } from 'react'
import { sendInvitation } from '../API/Invitations';

const CandidatsCreation = ({ isVisible, teams, cancel, reload }) => {
  const [emails, setEmails] = useState('')
  const [selectedTeam, setSelectedTeam] = useState(teams ? (teams[0]?._id ?? null) : null)
  const toast = useToast()


  const handleSubmit = async (event) => {
    event.preventDefault()
    const request = await sendInvitation(emails, selectedTeam)

    if (request.ok) {
      setEmails('')
      toast({
        title: "Invitations envoyées",
      })
      cancel()
      reload()
    } else {
      toast({
        title: "Une erreur est survenue",
        status: "error",
      })
    }

  }

  if (!isVisible) return null
  return (
    <AlertDialog
      isOpen={isVisible}
      onClose={() => cancel()}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight='bold'>
            Inviter un candidat
          </AlertDialogHeader>

          <AlertDialogBody>

            <form onSubmit={handleSubmit} id="invitCandidate">
              <FormControl gap={4} my='2' display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                <Flex direction={"column"}>

                  <FormLabel>Équipes</FormLabel>
                  <Select placeholder="Choisissez l'équipe" defaultValue={selectedTeam} variant='outline' required onChange={event => setSelectedTeam(event.target.value)}>
                    {teams.map(team => (
                      <option key={team._id} value={team._id}>
                        {team.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction={"column"}>
                  <FormLabel>Emails des futurs candidats (séparés par des virgules)</FormLabel>
                  <Input placeholder='paul@example.fr,robin@example.fr' variant='outline' required value={emails} onChange={event => setEmails(event.target.value)} />
                </Flex>
                <Divider />
              </FormControl>
            </form>
          </AlertDialogBody>

          <AlertDialogFooter display={"flex"} justifyContent={"space-between"} gap={4}>
            <Button flex={1} onClick={() => cancel()}>
              Fermer
            </Button>
            <Button flex={1} form="invitCandidate" colorScheme={"brand"} type="submit">Envoyer les invitations</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default CandidatsCreation
