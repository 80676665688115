import { Badge, Box, Button, Card, CircularProgress, CircularProgressLabel, Container, Flex, Grid, GridItem, Heading, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { getDoneQuizByCandidate } from '../../API/DoneQuiz';
import { applyInvitation, denyInvitation, getLoggedInvitations } from '../../API/Invitations';
import { getPublicJobOffers } from '../../API/JobOffers';
import { getRunningQuizByCandidate } from '../../API/RunningQuiz';
import EmptyDoneQuizCard from '../../Components/EmptyDoneQuizCard';
import EmptyRunningQuizCard from '../../Components/EmptyRunningQuizCard';
import JobOfferCard from '../../Components/JobOffers/JobOfferCard';
import { fadeIn } from '../../Components/Dashboard/Animation';

const CandidatHomepage = () => {
    const [runningQuiz, setRunningQuiz] = useState(null);
    const [doneQuiz, setDoneQuiz] = useState(null)
    const [candidateInvitations, setCandidateInvitations] = useState([])
    const [jobs, setJobs] = useState(null)
    const toast = useToast();
    const [largerThanLg] = useMediaQuery('(min-width: 1600px)')


    const fetchRunningQuiz = async () => {
        try {
            await getRunningQuizByCandidate("self", setRunningQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération du questionnaire en cours',
                status: 'error',
            })
        }
    }

    const fetchDoneQuiz = async () => {
        try {
            await getDoneQuizByCandidate("self", setDoneQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des questionnaires',
                status: 'error',
            })
        }
    }

    const fetchLastJobOffer = async () => {
        try {
            const response = await getPublicJobOffers();

            response.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));

            setJobs(response.slice(0, 6))
        } catch (error) {
        }
    }

    const fetchCandidateInvitations = async () => {
        try {
            await getLoggedInvitations(setCandidateInvitations);
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des invitations',
                status: 'error',
            })
        }
    }

    const acceptInvitation = async (invitationId) => {
        try {
            const response = await applyInvitation(invitationId);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message);
            }
            toast({
                title: 'Invitation acceptée'
            });
        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            });
        } finally {
            fetchCandidateInvitations();
        }
    }

    const refuseInvitation = async (invitationId) => {
        try {
            const response = await denyInvitation(invitationId);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message);
            }
            toast({
                title: 'Invitation refuseée'
            });
        } catch (error) {
            toast({
                title: 'Une erreur est survenue',
                description: error.message,
                status: 'error',
            });
        } finally {
            fetchCandidateInvitations();
        }
    }

    useEffect(() => {
        fetchRunningQuiz();
        fetchDoneQuiz();
        fetchLastJobOffer();
        fetchCandidateInvitations();
    }, [])

    return (
        <Container maxW="full" p={4}>
            <Flex gap={4} direction={largerThanLg ? "row" : "column"}>
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`} flex={3}>
                    <Heading pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Espace candidat</Heading>
                    <Box w='100%' my={4}>
                        <Heading mb={4} size={"lg"} color={"gray.700"}>Questionnaires à faire</Heading>
                        {runningQuiz && runningQuiz.length == 0 ? <EmptyRunningQuizCard isCandidate={true} /> : null}
                        <Grid gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]} gap={4}>
                            {runningQuiz && runningQuiz.map((infos, index) => {
                                const { quiz } = infos
                                return <Flex  p={4} key={index + "-running-quiz"} direction={"column"} bg={"lightBg.50"} borderRadius={"xl"} >

                                    <Flex justifyContent={"space-between"}>
                                        <Text as="b" fontSize='xl'>{quiz.name}</Text>
                                    </Flex>
                                    <Text mt={4} fontSize='md'>{quiz.description}</Text>
                                    <Box mt={4}>
                                        {quiz.tags.map((tag, index) => (
                                            <Badge key={"tag-" + index} mr={1}>{tag}</Badge>
                                        ))}
                                    </Box>
                                    <Box textAlign={"right"} my={4}>
                                        <Text as="i"><b>{quiz.questions.length}</b> questions</Text>
                                    </Box>
                                    <Flex mt={"auto"}>
                                        <Button as={Link} to={"/exam/" + infos._id} colorScheme='brand' rightIcon={<FaChevronRight />} flex={1} verticalAlign={"baseline"}>Commencer le questionnaire</Button>
                                    </Flex>
                                </Flex>
                            })}
                        </Grid>
                    </Box>
                    <Box w='100%' my={4}>
                        <Heading mb={4} size={"lg"} color={"gray.700"}>Questionnaires réalisés</Heading>
                        {doneQuiz && doneQuiz.length == 0 ? <EmptyDoneQuizCard isCandidate /> : null}
                        <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={4}>
                            {doneQuiz && doneQuiz.map((quiz, index) => {
                                return <Flex key={index + "-quiz"} p={4} direction="column" bg={"lightBg.50"} borderRadius={"xl"}>
                                    <Flex alignItems="center" justifyContent={"space-between"}>
                                        <Flex direction={"column"}>
                                            <Text as="b" fontSize='xl'>{quiz.name}</Text>
                                            <Text fontSize='md'>{quiz.description}</Text>
                                        </Flex>
                                        <Box>
                                            <CircularProgress value={(quiz.good / quiz.questions.length) * 100} color='green.400'>
                                                <CircularProgressLabel>{Math.round((quiz.good / quiz.questions.length) * 100)}%</CircularProgressLabel>
                                            </CircularProgress>
                                        </Box>
                                    </Flex>
                                </Flex>
                            })}
                        </Grid>
                    </Box>
                    {candidateInvitations && candidateInvitations.length > 0 &&
                        <Box w='100%' my={4}>
                            <Heading mb={4} size={"lg"} color={"gray.700"}>Invitations en attente</Heading>
                            <Grid gridTemplateColumns="repeat(4, 1fr)" gap={4}>
                                {candidateInvitations.map((invitation, index) => (
                                    <Flex key={index + "-invitation"} boxShadow={"base"} p={4} direction="column" bg={"white"}>
                                        <Text as="b" fontSize='xl' mb={4}>{`${invitation.team.companyName} - ${invitation.team.name}`}</Text>
                                        <Flex mt={"auto"} justifyContent={"space-between"} gap={4}>
                                            <Button colorScheme='red' flex={1} verticalAlign={"baseline"} onClick={() => refuseInvitation(invitation._id)}>
                                                Refuser
                                            </Button>
                                            <Button colorScheme='brand' flex={1} verticalAlign={"baseline"} onClick={() => acceptInvitation(invitation._id)}>
                                                Accepter
                                            </Button>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Grid>
                        </Box>
                    }
                </Flex>
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`} flex={2}>
                    <Heading pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Les dernières offres d'emplois</Heading>
                    <Grid gridTemplateColumns={["repeat(1, 1fr)"]} gap={4}>
                        {jobs && jobs.map((job, index) => <GridItem key={index}>
                            <JobOfferCard job={job} />
                        </GridItem>)}
                    </Grid>
                </Flex>
            </Flex>
        </Container>
    )
}

export default CandidatHomepage