import { Input } from '@chakra-ui/react';
import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_KEY } from "../keys";

const libraries = ['places'];

const CityAutocompleteInput = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_KEY,
        libraries,
    });

    // Fonction pour initialiser l'auto-complétion
    const initializeAutocomplete = () => {
        if (isLoaded && inputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ['geocode'],
                componentRestrictions: { country: 'fr' },
            });

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();

                if (place && place.vicinity) {
                    const postalCode = place.address_components.find((component) => component.types.includes('postal_code'));

                    if (postalCode) {
                        setInputValue(`${place.vicinity} ${postalCode.long_name}`);
                    } else {
                        setInputValue(place.vicinity);
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (isLoaded) {
            initializeAutocomplete();
        }
    }, [isLoaded]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        onChange(inputValue)
    }, [inputValue]);

    if (!isLoaded) return <p>Chargement...</p>;

    return (
        <>
            <Input
                ref={inputRef}
                type="text"
                placeholder="Paris 09"
                value={inputValue}
                onChange={(e) => onChange(e.target.value)}
            />
        </>
    );
};

export default CityAutocompleteInput;
