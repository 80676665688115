import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    IconButton,
    Text,
    Tooltip,
    useToast
} from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { changeJobApplicationStatus, getJobApplicationForTeam } from "../API/JobApplication";
import { getFileByKey } from '../API/S3';
import { formatDateForPrint } from "../Utils/FormatDate";
import StatusBadge from '../Utils/StatusBadge';
import { jobApplicationStatus } from "../Utils/translate";

const JobApplication = () => {
    const { jobId, jobApplicationId } = useParams();
    const [jobApplication, setJobApplication] = useState();

    const toast = useToast();

    useEffect(() => {
        try {
            getJobApplicationForTeam(jobId, jobApplicationId, setJobApplication);
        } catch (error) {
            // console.log(error);
        }
    }, [jobId, jobApplicationId]);

    const downloadFile = async (key) => {
        try {
            const response = await getFileByKey(key);

            if (!response.ok) {
                toast({
                    title: "Erreur lors de la récupération de l'image",
                    description: error.message,
                    status: "error"
                });

                throw new Error('Erreur lors de la récupération de l\'image');
            }

            // Convertir la réponse en blob (binary data)
            const blob = await response.blob();

            // Ouvrir le blob dans un nouvel onglet
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = "_blank";
            link.click();
        } catch (error) {
            toast({
                title: "Erreur lors de la création du lien",
                status: "error"
            })
        }
    }

    const handleUpdateStatus = async (status) => {
        try {
            await changeJobApplicationStatus(jobApplicationId, status, setJobApplication, toast);
        } catch (error) {
            toast({
                title: "Erreur lors de la modification du statut",
                status: "error"
            })
        }
    }

    if (!jobApplication) {
        return <div></div>;
    }

    return (
        <Box p="4">
            <Flex direction="column" >
                <Heading size="md">
                    Détails de la candidature
                </Heading>
                <Divider mt={2} mb={8} />
                <Flex direction="column" gap={4}>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            Offre d'emploi:
                        </Heading>
                        <Text>
                            {jobApplication.jobOffer.name}
                        </Text>
                    </Flex>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            Candidat:
                        </Heading>
                        <Text>
                            {jobApplication.candidate.firstname} {jobApplication.candidate.lastname}
                        </Text>
                    </Flex>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            Statut:
                        </Heading>
                        <Text>
                            <StatusBadge status={jobApplicationStatus[jobApplication.status]} />
                        </Text>
                    </Flex>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            CV:
                        </Heading>
                        <Text>
                            <Tooltip label='Télécharger' placement='top'>
                                <IconButton rounded="full" size={"sm"} aria-label='Télécharger' icon={<FaDownload />} onClick={() => downloadFile(jobApplication.cv)} />
                            </Tooltip>
                        </Text>
                    </Flex>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            Lettre de motivation:
                        </Heading>
                        <Text>
                            <Tooltip label='Télécharger' placement='top'>
                                <IconButton rounded="full" size={"sm"} aria-label='Télécharger' icon={<FaDownload />} onClick={() => downloadFile(jobApplication.cover)} />
                            </Tooltip>
                        </Text>
                    </Flex>
                    <Flex gap={4} alignItems="center">
                        <Heading size="sm">
                            Date de candidature:
                        </Heading>
                        <Text>
                            {formatDateForPrint(jobApplication.createdAt)}
                        </Text>
                    </Flex>
                </Flex>
                <Flex justifyContent="flex-end" gap={2} mt={4}>
                    {jobApplication.status !== 'accepted' && (
                        <Button
                            colorScheme="brand"
                            onClick={() => handleUpdateStatus('accepted')}
                        >
                            Accepter
                        </Button>
                    )}
                    {jobApplication.status !== 'pending' && (
                        <Button
                            colorScheme="yellow"
                            onClick={() => handleUpdateStatus('pending')}
                        >
                            Mettre en cours d'analyse
                        </Button>
                    )}
                    {jobApplication.status !== 'rejected' && (
                        <Button
                            colorScheme="red"
                            onClick={() => handleUpdateStatus('rejected')}
                        >
                            Refuser
                        </Button>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default JobApplication;

