import { Box, Button, Container, Flex, Heading, Input, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
import { getTeamsByUser } from "../API/Candidats";
import { getPendingInvitations } from '../API/Invitations';
import { UserContext } from '../Authentication/ProtectedRoute';
import CandidatsCreation from '../Components/CandidatsCreation';
import CandidatsTable from '../Components/CandidatsTable';
import { fadeIn } from '../Components/Dashboard/Animation';
import InvitationsTable from '../Components/InvitationsTable';

const CandidatsComponent = () => {
    const [candidats, setCandidats] = useState([])
    const [invitations, setInvitations] = useState([])
    const [isCandidatCreationVisible, setIsCandidatCreationVisible] = useState(false)
    const { user } = useContext(UserContext);
    const [searchBar, setSearchBar] = useState("")
    const toast = useToast()

    useEffect(() => {
        loadUserCandidats()
        loadInvitations()
    }, [])

    useEffect(() => {
    }, [user])


    const loadInvitations = async () => {
        try {
            const data = await getPendingInvitations();
            setInvitations(data)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des invitations',
                status: 'error',
            })
        }
    }

    const loadUserCandidats = async () => {
        try {
            const data = await getTeamsByUser();
            const transformedTeams = data.map(team => {
                return team.candidate.map(candidate => {
                    return {
                        team,
                        ...candidate
                    };
                });
            }).flat();
            setCandidats(transformedTeams)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des candidats',
                status: 'error',
            })
        }
    }


    return (
        <>
            <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Candidats</Heading>
            <Container maxW="full" p={4}>
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} mb={8} animation={`${fadeIn} .4s ease-in`}>
                    <Flex gap={4} justifyContent={"space-between"} alignItems={"center"}>
                        <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Vos candidats</Heading>
                        <Flex gap={4}>
                            <Button leftIcon={<FaPlusSquare />} onClick={() => setIsCandidatCreationVisible(true)} size="sm" colorScheme="brand" variant="ghost">
                                Inviter un candidat
                            </Button>
                            <CandidatsCreation isVisible={isCandidatCreationVisible} teams={user.teams} cancel={() => setIsCandidatCreationVisible(false)} reload={loadInvitations} />
                        </Flex>
                    </Flex>
                    <Box my={4}>
                        <Input variant='flushed' placeholder='Rechercher un candidat' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                    </Box>
                    <Box w='100%' borderRadius={"md"}>
                        <CandidatsTable candidats={candidats.filter(candidate =>
                            candidate.firstname.toLowerCase().includes(searchBar.toLowerCase()) ||
                            candidate.lastname.toLowerCase().includes(searchBar.toLowerCase()) ||
                            candidate.email.toLowerCase().includes(searchBar.toLowerCase()))}
                            reload={loadUserCandidats} />
                    </Box>
                </Flex>

                <Box h={8} borderBottom="1px solid #E2E8F0" />
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`}>
                    <Flex gap={4} justifyContent={"space-between"} alignItems={"center"}>
                        <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Vos invitations en attente</Heading>
                        <Flex gap={4}>
                        </Flex>
                    </Flex>
                    <Box w='100%' borderRadius={"md"}>
                        <InvitationsTable invitations={invitations} reload={loadInvitations} />
                    </Box>
                </Flex>
            </Container>
        </>
    )
}

export default CandidatsComponent