import React, { useEffect } from 'react';
import { Box, Button, Flex, IconButton, Input, Text } from '@chakra-ui/react';
import { FaFile, FaFilePdf, FaFileWord, FaImage, FaTrash } from 'react-icons/fa6';

const getLogoFromExtension = (extension) => {
    switch (extension) {
        case 'pdf':
            return <Flex
                p={4}
                alignItems={"center"}
                borderRadius={"md"}
                justifyContent={"center"}
                bg={`linear-gradient(135deg, #D69E2E 0%, #805AD5 100%)`}
            ><FaFilePdf color='white' size={20} /></Flex>
        case 'png':
            return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #805AD5 0%, #086F83 100%)`}>
                <FaImage color='white' size={20} />
            </Flex>
        case 'jpg':
        case 'jpeg':
            return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #3182ce 0%, #805AD5 100%)`}><FaImage color='white' size={20} /></Flex>
        case 'doc':
            return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #3182ce 0%, #805AD5 100%)`}><FaFileWord color='white' size={20} /></Flex>
        case 'docx':
            return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #D53F8C 0%, #086F83 100%)`}><FaFileWord color='white' size={20} /></Flex>
        default:
            return <Flex p={4} alignItems={"center"} borderRadius={"md"} justifyContent={"center"} bg={`linear-gradient(135deg, #D53F8C 0%, #086F83 100%)`}><FaFile color='white' size={20} /></Flex>
    }
}

const FileUploaderComponent = ({ file, questionIndex, handleImageRemove, handleImageChange, phrase = "une image", accept = "image/*" }) => {
    const handleFileChange = (e) => {
        if (e.target.files[0])
            handleImageChange(questionIndex, e.target.files[0]);
    };

    return (
        <Flex direction="column">
            {file ?
                <Flex border={"1px solid #EDF2F7"} borderRadius={"xl"} flex={1} p={4} alignItems={"center"} direction={["column", "row"]} gap={4}>
                    {getLogoFromExtension(file.name.split('.').pop())}
                    <Text>{file.name}</Text>
                    <IconButton ml="auto" colorScheme="red" aria-label='Delete' isRound onClick={() => handleImageRemove()}><FaTrash /></IconButton>
                </Flex> :
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="2px dashed #319795"
                    borderRadius="8px"
                    height="200px"
                    width="100%"
                    position="relative"
                    p={4}
                    _hover={{ borderColor: 'brand.600' }}
                >
                    <Input
                        type="file"
                        accept={accept}
                        onChange={handleFileChange}
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        opacity="0"
                        cursor="pointer"
                        zIndex="1"
                    />
                    <Box zIndex="0" textAlign="center">
                        <Text fontSize="lg" color="gray.500">
                            Glisser-déposer ou cliquer pour téléverser {phrase}
                        </Text>
                        <Button mt={2} size="sm" colorScheme="brand">
                            Choisir un fichier
                        </Button>
                    </Box>
                </Box>
            }
        </Flex>
    );
};

export default FileUploaderComponent;