import { Button, Card, Container, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { getJobOfferById } from '../API/JobOffers';
import { FaArrowLeft } from 'react-icons/fa6';
import JobOfferHeader from '../Components/JobOffers/JobOfferHeader';
import JobApplication from '../Components/JobOffers/JobApplication';

const JobSearchInfosComponent = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null)

  useEffect(() => {
    getJobOfferById(jobId).then((data) => {
      setJob(data)
    })
  }, [])

  return (
    <>
      <Container maxW="full" p={4}>
        <Flex alignItems='center' gap={4} mb={4}>
          <Button as={Link} to={"/offres-emploi"} size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux offres d'emploi</Button>
        </Flex>
        <Flex direction="column" gap={4} mb={6}>
          <JobOfferHeader {...job} />
          <Card p={12}>
            <span dangerouslySetInnerHTML={{ __html: job && job.content }}></span>
          </Card>
        </Flex>
        <Flex direction="column" gap={4}>
          <Card px={4} py={8}>
            <JobApplication job={job} />
          </Card>
        </Flex>
      </Container>
    </>
  )
}

export default JobSearchInfosComponent