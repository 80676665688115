import { Avatar, Box, Divider, Flex, IconButton, Image, Text, useMediaQuery, Menu, MenuButton, MenuItem, MenuList, MenuDivider } from '@chakra-ui/react'
import HoritzontalLogo from '../Resources/Logos/aptitude_recrutement.svg';
import SmallLogo from '../Resources/Logos/square_aptitude_recrutement.png';
import React, { useContext, useEffect, useState } from 'react'
import { FaArrowRight, FaBriefcase, FaGauge, FaListCheck, FaUserGraduate, FaBars } from 'react-icons/fa6';
import { UserContext } from '../Authentication/ProtectedRoute';
import { useLocation, Link } from 'react-router-dom';
import NavbarLinkItem from './Navbar/NavbarLinkItem';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const Navbar = () => {
    const { user } = useContext(UserContext);
    const location = useLocation();
    const [largerThanLg] = useMediaQuery('(min-width: 1600px)')
    const [isExpanded, setIsExpanded] = useState(true);
    const [isMobile] = useMediaQuery('(max-width: 768px)');


    const [currentLocation, setCurrentLocation] = useState("");

    useEffect(() => {
        setCurrentLocation(location.pathname)
    }, [location])

    const toggleSideBar = () => {
        setIsExpanded(!isExpanded);
    }

    const renderNavbarLinks = () => (
        <>
            <NavbarLinkItem
                logo={<FaGauge color={currentLocation === "/" ? "white" : "#4A5568"} />}
                text="Tableau de bord"
                link="/"
                isActive={currentLocation === "/"}
                largerThanLg={largerThanLg}
                isExpanded={isExpanded}
                isMobile={isMobile}
            />
            {user && user.role !== "candidate" &&
                <>
                    <NavbarLinkItem
                        logo={<FaListCheck color={currentLocation === "/questionnaires" ? "white" : "#4A5568"} />}
                        text="Questionnaires"
                        link="/questionnaires"
                        isActive={currentLocation === "/questionnaires"}
                        largerThanLg={largerThanLg}
                        isExpanded={isExpanded}
                        isMobile={isMobile}
                    />
                    <NavbarLinkItem
                        logo={<FaUserGraduate color={currentLocation.startsWith("/candidat") ? "white" : "#4A5568"} />}
                        text="Candidats"
                        link="/candidats"
                        isActive={currentLocation.startsWith("/candidat")}
                        largerThanLg={largerThanLg}
                        isExpanded={isExpanded}
                        isMobile={isMobile}
                    />
                    <NavbarLinkItem
                        logo={<FaBriefcase color={currentLocation.startsWith("/offre-emploi") ? "white" : "#4A5568"} />}
                        text="Offres d'emploi"
                        link="/offre-emploi"
                        isActive={currentLocation.startsWith("/offre-emploi")}
                        largerThanLg={largerThanLg}
                        isExpanded={isExpanded}
                        isMobile={isMobile}
                    />
                </>
            }
            {user && user.role === "candidate" &&
                <NavbarLinkItem
                    logo={<FaBriefcase color={currentLocation.startsWith("/offres-emploi") ? "white" : "#4A5568"} />}
                    text="Offres d'emploi"
                    link="/offres-emploi"
                    isActive={currentLocation.startsWith("/offres-emploi")}
                    largerThanLg={largerThanLg}
                    isExpanded={isExpanded}
                    isMobile={isMobile}
                />
            }
        </>
    );


    return (
        <>
            {isMobile ? (
                <Box
                    position="fixed"
                    top="10px"
                    right="10px"
                    zIndex={1000}
                >
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<FaBars />}
                            size="lg"
                            bg="gray.50"
                            rounded="full"
                            aria-label="User Profile"
                        />
                        <MenuList>
                            {renderNavbarLinks()}
                            <MenuDivider />
                            <Link to="/profil">
                                <NavbarLinkItem
                                    logo={<Avatar src={user.blob} size="xs" />}
                                    text="Mon profil"
                                    link="/profil"
                                    isActive={currentLocation === "/profil"}
                                    largerThanLg={largerThanLg}
                                    isExpanded={isExpanded}
                                    isMobile={isMobile}
                                />
                            </Link>
                        </MenuList>
                    </Menu>
                </Box>
            ) : (
                <Flex
                    minW={largerThanLg && isExpanded ? "300px" : "125px"}
                    w={!largerThanLg && isExpanded ? "125px" : "fit-content"}
                    p={5} pb={10} pt={10}
                    direction={"column"}
                    alignItems={"center"}
                    bg={"white"}
                    height={"100vh"}
                    position={"sticky"}
                    top={0}
                    boxShadow={"lg"}
                >
                    <Link to="/">
                        <Image src={largerThanLg && isExpanded ? HoritzontalLogo : SmallLogo} w={largerThanLg && isExpanded ? "200px" : "50px"} alt="logo aptitude" cursor={"pointer"} />
                    </Link>
                    <Divider pb={2} pt={4} />
                    <Flex direction={"column"} gap={2} w={"100%"} mt={5} alignItems={"center"} minHeight={"280px"}>
                        {renderNavbarLinks()}
                    </Flex>
                    <Flex mt="auto" direction={"column"} gap={4} w={"100%"} alignItems={"center"}>
                        {largerThanLg && isExpanded ?
                            <Flex
                                gap={4}
                                alignItems={"center"}
                                mt={"auto"}
                                bg="brand.50"
                                borderRadius={8}
                                transition={"0.2s"}
                                _hover={{ bg: "brand.100" }}
                                cursor={"pointer"}
                                as={Link}
                                to="/profil"
                                p={4}
                                w={"100%"}
                            >
                                <Avatar
                                    name={user?.firstname + " " + user?.lastname}
                                    src={user.blob}
                                    size="sm"
                                />
                                <Text fontSize={"md"} color={"gray.700"} fontWeight={"semibold"} textTransform="uppercase">{user.firstname + " " + user.lastname}</Text>
                                <Box ml="auto" color='gray.600'>
                                    <FaArrowRight />
                                </Box>
                            </Flex>
                            : <Avatar
                                name={user?.firstname + " " + user?.lastname}
                                src={user.blob}
                                size="md"
                                as={Link}
                                to="/profil"
                                mt={"auto"}
                            />
                        }
                        <Flex
                            borderRadius={8}
                            w={"100%"}
                            p={4}
                            height={"60px"}
                            background={"lightBg.100"}
                            cursor={"pointer"}
                            _hover={{ bg: "lightBg.700" }}
                            justifyContent={largerThanLg && isExpanded ? "space-between" : "center"}
                            alignItems={"center"}
                            onClick={toggleSideBar}
                            display={!largerThanLg ? "none" : "flex"}
                        >
                            <Text display={largerThanLg && isExpanded ? "block" : "none"} fontSize={"md"} color={"gray.700"} fontWeight={"semibold"} >Réduire la navigation</Text>
                            {!isExpanded ? <MdOutlineKeyboardArrowRight size={25} color="gray.100" /> : <MdOutlineKeyboardArrowLeft size={25} color="gray.100" />}
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    )
}

export default Navbar

