import csrfFetch from '../Utils/CSRF';

export const getTeamByUser = async () => {
    const response = await csrfFetch('/teams/getteamsbyuser');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        return data;
    } else {
        return [];
    }
}

export const getTeamById = async (id) => {
    return await csrfFetch('/teams/' + id);
}

export const addMemberToTeam = async (teamId, userId) => {
    return await csrfFetch(`/teams/${teamId}/member/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}

export const promoteMemberById = async (teamId, memberId) => {
    return await csrfFetch(`/teams/${teamId}/promote/${memberId}`, {
        method: 'PUT'
    });
}

export const removeMember = async (teamId, memberId) => {
    return await csrfFetch(`/teams/${teamId}/member/${memberId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}

export const removeCandidate = async (teamId, candidateId) => {
    return await csrfFetch(`/teams/${teamId}/candidate/${candidateId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
    });
}


export const updateTeam = async (teamId, teamData) => {
    return await csrfFetch(`/teams/${teamId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(teamData)
    });
}

export const createTeam = async ({ companyName, name, description }) => {
    return await csrfFetch(`/teams`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ companyName, name, description })
    });
}