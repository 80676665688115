import React from 'react';
import CheckboxComponent from './CandidateInputs/CheckboxComponent';
import ShortComponent from './CandidateInputs/ShortComponent';

const componentsMap = {
    unique: CheckboxComponent,
    multiple: CheckboxComponent,
    short: ShortComponent,
};

const DynamicComponent = ({ type, ...props }) => {
    // Récupérer le composant à partir du type
    const Component = componentsMap[type] || null;
    // Si le composant n'existe pas, on peut rendre un fallback ou null
    if (!Component) {
        return <div>Composant non trouvé</div>;
    }

    // Rendre dynamiquement le composant avec les props reçus
    return <Component {...props} />;
};

export default DynamicComponent;
