import React, { useState } from 'react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button,
    VStack,
    Input,
    Box,
    Flex,
    Image,
    Text,
    useToast,
    Link as ChakraLink
} from '@chakra-ui/react'
import { useNavigate, useLocation, Link } from "react-router-dom";
import backgroundImage from "../Resources/Images/login_illustration.jpg";
import HorizontalLogo from "../Resources/Logos/aptitude_recrutement.svg";
import { login } from '../API/Users';
import Footer from '../Layout/Footer';

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})
    const toast = useToast()
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";


    const handleEmailChange = (e) => {
        const { value } = e.target;
        if (!checkIfEmailIsValid(value)) {
            setErrors({ ...errors, email: "Le format de l'adresse mail est incorrect." })
        } else {
            if (errors.hasOwnProperty("email")) {
                delete errors.email
                setErrors(errors)
            }
        }
        setEmail(value)
    }

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value)
    }

    const checkIfEmailIsValid = (value) => {
        if (value === "")
            return true
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const user = await login(email, password);
            if (!user) {
                throw new Error('Utilisateur non trouvé.');
            }

            if (!user.email) {
                toast({
                    title: 'Erreur.',
                    description: "Vos identifiants sont incorrects ou ne correspondent à aucun compte.",
                    status: 'error',
                })
            } else {
                navigate(from);
            }
        } catch (error) {
            toast({
                title: 'Erreur.',
                description: "Une erreur est survenue lors de votre requête.",
                status: 'error',
            })
        }
    }

    return (
        <Flex direction={{ base: "column", md: "row" }} minHeight="100vh">
            <Flex flex={5} direction={"column"}>
                <Box p={8}>
                    <img width={250} src={HorizontalLogo} alt="Aptitude logo horizontal" />
                </Box>
                <Flex flex={1} justifyContent={"center"} alignItems={"center"} p={4}>
                    <Box width={500}>
                        <form onSubmit={handleSubmit}>
                            <VStack
                                spacing={4}
                                align='stretch'
                            >
                                <FormControl isInvalid={errors && errors.email}>
                                    <FormLabel>Adresse email</FormLabel>
                                    <Input type='email' value={email} onChange={handleEmailChange} placeholder="Renseignez votre adresse email" />
                                    {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                                </FormControl >
                                <FormControl isInvalid={errors && errors.email}>
                                    <FormLabel>Mot de passe</FormLabel>
                                    <Input type='password' value={password} onChange={handlePasswordChange} placeholder="Renseignez votre mot de passe" />
                                    {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                                </FormControl >
                                <Button
                                    isDisabled={!checkIfEmailIsValid(email) || password === "" || email === ""}
                                    mt={4}
                                    colorScheme='brand'
                                    onClick={handleSubmit}
                                    mb={0}
                                    type='submit'
                                >
                                    Connexion
                                </Button>
                            </VStack>
                        </form>
                        <ChakraLink>
                            <Link fontSize='xs' mt={4} to="/mot-de-passe-oublie">Vous avez oublié votre mot de passe ?</Link>
                        </ChakraLink>
                    </Box>
                </Flex>
                <Box display={{ base: "none", md: "block" }} >
                    <Footer hidePartnerProgram />
                </Box>
            </Flex>
            <Box flex={2} display={{ base: "none", md: "block" }}>
                <Image src={backgroundImage} objectFit="cover" alt="background image" height={{ base: "200px", md: "100vh" }} width={"100%"}></Image>
            </Box>
            <Box display={{ base: "block", md: "none" }} mt={"auto"} >
                <Footer  />
            </Box>
        </Flex>
    )
}

export default LoginForm
