import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

import {
    Button,
    Flex,
    IconButton,
    Text,
} from "@chakra-ui/react";
import React from "react";

const ButtonPagination = (props) => {
    const {
        children,
        index,
        setPageIndex,
        pageIndex,
        colorScheme="brand",
    } = props;

    return (
        <Button
            size="sm"
            onClick={() => {
                setPageIndex(index);
            }}
            colorScheme={colorScheme}
            variant={pageIndex === index ? "solid" : "link"}
        >
            {children}
        </Button>
    );
};

const PaginationTable = (props) => {
    const {
        pageSize,
        pageIndex,
        setPageIndex,
        totalItemsCount,
        colorScheme = "brand",
    } = props;

    const showButtons = () => {
        let buttons = [];
        const TOTAL_INDEX = Math.ceil(totalItemsCount / pageSize);

        if (TOTAL_INDEX <= 5) {
            for (let index = 0; index < TOTAL_INDEX; index++) {
                buttons.push(
                    <ButtonPagination
                        key={index}
                        colorScheme={colorScheme}
                        setPageIndex={setPageIndex}
                        index={index}
                        pageIndex={pageIndex}
                    >
                        {index + 1}
                    </ButtonPagination>
                );
            }
        } else {
            let start = Math.max(0, pageIndex - 2);
            let end = Math.min(TOTAL_INDEX - 1, start + 4);

            if (end === TOTAL_INDEX - 1) {
                start = Math.max(0, end - 4);
            }

            for (let index = start; index <= end; index++) {
                buttons.push(
                    <ButtonPagination
                        colorScheme={colorScheme}
                        setPageIndex={setPageIndex}
                        index={index}
                        key={index}
                        pageIndex={pageIndex}
                    >
                        {index + 1}
                    </ButtonPagination>
                );
            }
        }

        buttons.unshift(
            <IconButton
                icon={<FaArrowLeft />}
                size="sm"
                onClick={() => {
                    setPageIndex(pageIndex - 1);
                }}
                isDisabled={!(pageIndex > 0)}
                colorScheme={colorScheme}
                key={"left"}
                variant="link"
            >
                Atras
            </IconButton>
        );

        buttons.push(
            <IconButton
                icon={<FaArrowRight />}
                size="sm"
                onClick={() => {
                    setPageIndex(pageIndex + 1);
                }}
                isDisabled={!(pageIndex + 1 < TOTAL_INDEX)}
                colorScheme={colorScheme}
                variant="link"
                key={"right"}
            >
                Atras
            </IconButton>
        );

        return buttons;
    };
    return <Flex p={4} justifyContent={"center"}>
        <Flex flex={1} alignItems={"center"}>
            <Text flex={1} p={0} size={"md"} fontWeight={"semibold"} color={"gray.700"}>Nombre d'éléments : {totalItemsCount}</Text>
        </Flex>
        <Flex flex={1} alignItems={"center"} justifyContent={"center"}>{showButtons()}</Flex>
        <Flex flex={1} alignItems={"center"}>
            <Text flex={1} p={4} pb={0} pl={0} size={"md"} fontWeight={"semibold"} color={"gray.700"}></Text>
        </Flex>
    </Flex>
};

export default PaginationTable;