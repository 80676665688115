import { Container, Divider, Heading, Link, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';

const MentionLegalesComponent = () => {
    return (
        <>
            <Navbar />
            <Container maxW="container.xl" p={4}>
                <Stack spacing={4}>
                    <Heading as="h1" size="xl">Mentions légales</Heading>
                    <Text>
                        En vertu de <Link href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000801164#LEGIARTI000042038977" target="_blank" rel="noreferrer noopener">l'article 6 de la loi n° 2004-575 du 21 juin 2004</Link> pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            <strong>Propriétaire du site : </strong>
                            <span>Start-Win</span>
                            <br />
                            <strong>Contact :</strong>
                            <span>robin@start-win.fr</span>
                            <span>0683238653</span>
                            <br />
                            <strong>Adresse :</strong>
                            <span>31 LA NOUILLERE 44330 VALLET</span>
                        </ListItem>
                        <ListItem>
                            <strong>Identification de l'entreprise :</strong>
                            <span>EI</span>
                            <span>Start-Win</span>
                            <br />
                            <strong>SIREN :</strong> 82387976200040
                            <br />
                            <strong>Adresse postale :</strong>
                            <span>31 LA NOUILLERE 44330 VALLET</span>
                        </ListItem>
                        <ListItem>
                            <strong>Directeur de la publication :</strong>
                            <span>Couet Robin</span>
                            <br />
                            <strong>Contact :</strong>
                            <span>robin@start-win.fr</span>
                        </ListItem>
                        <ListItem>
                            <strong>Hébergeur :</strong>
                            <span>OVH SAS – 2 rue Kellermann – BP 80157 – 59053 Roubaix Cedex 1 – Téléphone : 1007</span>
                        </ListItem>
                        <ListItem>
                            <strong>Délégué à la protection des données :</strong>
                            <span>Robin Couet</span>
                            <br />
                            <strong>Contact :</strong>
                            <span>robin@start-win.fr</span>
                        </ListItem>
                    </UnorderedList>
                    <Divider />
                    <Heading as="h2" size="lg">2 – Propriété intellectuelle et contrefaçons.</Heading>
                    <Text>
                        <strong><span>Start-Win</span></strong> est propriétaire des droits de propriété intellectuelle et détient les droits d'usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.
                    </Text>
                    <Text>
                        Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de <strong><span>Start-Win</span></strong>.
                    </Text>
                    <Text>
                        Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles <Link href="https://www.legifrance.gouv.fr/affichCodeArticle.do?idArticle=LEGIARTI000032655082&cidTexte=LEGITEXT000006069414&dateTexte=20160605" target="_blank" rel="noreferrer nofollow noopener">L.335-2 et suivants du Code de Propriété Intellectuelle</Link>.
                    </Text>
                    <Divider />
                    <Heading as="h2" size="lg">3 – Limitations de responsabilité.</Heading>
                    <Text>
                        <strong><span>Start-Win</span></strong> ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link>.
                    </Text>
                    <Text>
                        <strong><span>Start-Win</span></strong> décline toute responsabilité quant à l'utilisation qui pourrait être faite des informations et contenus présents sur <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link>.
                    </Text>
                    <Text>
                        <strong><span>Start-Win</span></strong> s'engage à sécuriser au mieux le site <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link>, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.
                    </Text>
                    <Text>
                        Des espaces interactifs (espace contact ou commentaires) sont à la disposition des utilisateurs. <strong><span>Start-Win</span></strong> se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.
                    </Text>
                    <Text>
                        Le cas échéant, <strong><span>Start-Win</span></strong> se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l'utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).
                    </Text>
                    <Divider />
                    <Heading as="h2" size="lg">4 – CNIL et gestion des données personnelles.</Heading>
                    <Text>
                        Conformément aux dispositions de <Link href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000886460" target="_blank" rel="noreferrer nofollow noopener">la loi 78-17 du 6 janvier 1978 modifiée</Link>, l'utilisateur du site <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link> dispose d'un droit d'accès, de modification et de suppression des informations collectées. Pour exercer ce droit, envoyez un message à notre Délégué à la Protection des Données : <strong><span>Robin Couet</span></strong> – <strong><span>robin@start-win.fr</span></strong>.
                    </Text>
                    <Text>
                        Pour plus d'informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire…), lisez notre <Link href="https://start-win.fr/mentions-legales/"><strong>https://start-win.fr/mentions-legales/</strong></Link>.
                    </Text>
                    <Divider />
                    <Heading as="h2" size="lg">5 – Liens hypertextes et cookies</Heading>
                    <Text>
                        Le site <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link> contient des liens hypertextes vers d'autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d'autres sites vers <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link>.
                    </Text>
                    <Text>
                        La navigation sur le site <Link href="https://start-win.fr/"><strong>https://start-win.fr/</strong></Link> est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur.
                    </Text>
                </Stack>
            </Container>
            <Footer />
        </>
    )
}

export default MentionLegalesComponent;