import { Button, Container, Flex, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoSend } from "react-icons/io5"
import { useNavigate, useParams } from 'react-router-dom'
import { createDoneQuiz } from '../API/DoneQuiz'
import { getRunningQuiz } from '../API/RunningQuiz'
import ExamHeaderComponent from '../Components/ExamHeaderComponent'
import ExamQuestionCard from '../Components/ExamQuestionCard'


const CandidatQuizExam = () => {
    const { runningQuizId } = useParams();
    const [quiz, setQuiz] = useState(null)
    const [responsesChecked, setResponsesChecked] = useState([])
    const navigate = useNavigate()
    const toast = useToast()

    const fetchExamQuiz = async (id) => {
        try {
            await getRunningQuiz(id, setQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération du quiz',
                status: 'error',
            })
        }
    }

    const handleResponseChange = (questionIndex, responsesIndex, value, type = "check") => {
        let copy = [...responsesChecked]

        if (type == "check") {
            if (value) {
                if (copy[questionIndex].includes(responsesIndex)) return
                copy[questionIndex].push(responsesIndex)
            } else {
                copy[questionIndex] = copy[questionIndex].filter(el => el != responsesIndex)
            }
        } else if (type == "text") {
            copy[questionIndex][responsesIndex] = value
        }

        setResponsesChecked(copy)
    }

    const handleSubmit = async () => {
        const { name, description, questions } = quiz;

        try {
            const response = await createDoneQuiz({
                runningQuizId,
                name,
                description,
                questions,
                answers: responsesChecked
            })

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            navigate("/")

        } catch (error) {
            toast({
                title: 'Erreur lors de la soumission du questionnaire',
                status: 'error',
            })
        }
    }

    useEffect(() => {
        fetchExamQuiz(runningQuizId);
    }, [])

    useEffect(() => {
        if (quiz) {
            let emptyArray = []
            quiz.questions.map(() => emptyArray.push([]))
            setResponsesChecked(emptyArray)
        }
    }, [quiz])


    if (!quiz) return null
    return (
        <>
            <Container maxW="full" p={4}>
                <ExamHeaderComponent {...quiz} />
                {quiz.questions.map((question, index) => (
                    <ExamQuestionCard key={index} {...question} questionIndex={index} handleChange={handleResponseChange} responsesChecked={responsesChecked} />
                ))}

                <Flex mt={4} justifyContent={"flex-end"}>
                    <Button colorScheme='brand' rightIcon={<IoSend />} onClick={() => handleSubmit()}>Envoyer mes réponses</Button>
                </Flex>
            </Container>
        </>
    )
}

export default CandidatQuizExam