import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Card, CircularProgress, CircularProgressLabel, Container, Divider, Flex, Grid, Heading, IconButton, Select, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaArrowLeft, FaEdit, FaFileSignature, FaTrash } from 'react-icons/fa';
import { FaArrowRight, FaBriefcase, FaBusinessTime, FaFileLines, FaMapPin, FaMoneyBill, FaPlus } from 'react-icons/fa6';
import { IoRefresh } from "react-icons/io5";
import { Link, useParams } from 'react-router-dom';
import { getAttachmentsByCandidate } from '../API/Attachements';
import { addOrRemoveJobOffer, getCandidate, updateCandidatStatus } from '../API/Candidats';
import { getDoneQuizByCandidate } from '../API/DoneQuiz';
import { getJobOffersByUserTeams } from '../API/JobOffers';
import { removeNote } from '../API/Note';
import { loadUserQuizzes } from '../API/Quizzes';
import { getRunningQuizByCandidate, removeRunningQuiz, storeRunningQuiz } from '../API/RunningQuiz';
import { UserContext } from '../Authentication/ProtectedRoute';
import { AddNoteDialogueComponent } from '../Components/AddNoteDialogueComponent';
import AttachmentList from '../Components/AdminCandidatePage/AttachmentList';
import AddAttachmentDialog from '../Components/AdminCandidatePage/Dialog/AddAttachmentDialog';
import CandidatAddQuiz from '../Components/CandidatAddQuiz';
import { fadeIn } from '../Components/Dashboard/Animation';
import EmptyAttachmentCard from '../Components/EmptyAttachmentCard';
import EmptyDoneQuizCard from '../Components/EmptyDoneQuizCard';
import EmptyRunningQuizCard from '../Components/EmptyRunningQuizCard';
import candidateBg from "../Resources/Images/candidate_bg.jpg";
import { displayOfferEndMessage, formatDateForPrint } from '../Utils/FormatDate';

const CandidatComponent = () => {
    const { candidatId } = useParams();
    const [candidat, setCandidat] = useState(null)
    const [quizzes, setQuizzes] = useState(null)
    const [isAddQuizVisible, setIsAddQuizVisible] = useState(false)
    const [runningQuiz, setRunningQuiz] = useState(null);
    const [doneQuiz, setDoneQuizRouter] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [deleteNote, setDeleteNote] = useState(null)
    const [updateNote, setUpdateNote] = useState(null)
    const [jobOffers, setJobOffers] = useState(null)
    const [attachments, setAttachments] = useState(null)
    const { isOpen: isOpenAddJobOffer, onOpen: onOpenAddJobOffer, onClose: onCloseAddJobOffer } = useDisclosure();
    const { isOpen: isOpenAddAttachment, onOpen: onOpenAddAttachment, onClose: onCloseAddAttachment } = useDisclosure();
    const cancelRef = useRef()
    const cancelRefAttachment = useRef()
    const toast = useToast();
    const { user } = useContext(UserContext);
    const [candidatStatus, setCandidatStatus] = useState("Nouveau");



    const fetchCandidat = async () => {
        try {
            await getCandidate(candidatId, setCandidat)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération du candidat',
                status: 'error'
            })
        }
    };

    const updateRunningQuiz = async (quizId) => {
        try {
            const response = await storeRunningQuiz(quizId, candidat._id);

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            fetchRunningQuiz();
        } catch (error) {
            toast({
                title: 'Erreur lors de la sauvegarde du questionnaire',
                status: 'error'
            })
        }
    }

    const fetchRunningQuiz = async () => {
        try {
            await getRunningQuizByCandidate(candidat._id, setRunningQuiz)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des questionnaires',
                status: 'error'
            })
        }
    }

    const fetchCandidateAttachments = async () => {
        try {
            await getAttachmentsByCandidate(candidat._id, setAttachments)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des pièces jointes',
                status: 'error'
            })
        }
    }

    const fetchDoneQuiz = async () => {
        try {
            await getDoneQuizByCandidate(candidat._id, setDoneQuizRouter)
        } catch (error) {
            toast({
                title: 'Erreur lors de la récupération des questionnaires',
                status: 'error'
            })
        }
    }

    const deleteRunningQuiz = async (runningQuizId) => {
        try {
            const response = await removeRunningQuiz(runningQuizId);

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            fetchRunningQuiz();

        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression du questionnaire',
                status: 'error'
            })
        }
    }

    const deleteCandidateNote = async (noteId) => {
        try {
            const response = await removeNote(noteId);

            if (!response.ok) {
                throw new Error('Failed to delete note');
            }

            toast({
                title: 'Note supprimée',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            fetchCandidat();
        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression de la note',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
    }


    useEffect(() => {
        try {
            loadUserQuizzes(setQuizzes);
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des questionnaires',
                status: 'error'
            })
        }
        fetchCandidat();
    }, [])

    useEffect(() => {
        if (candidat) {
            fetchRunningQuiz()
            fetchDoneQuiz()
            fetchCandidateAttachments();
            setCandidatStatus(candidat.status)
        }

    }, [candidat, quizzes])

    useMemo(() => {
        try {
            getJobOffersByUserTeams(setJobOffers)
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des offres d\'emploi.',
                status: 'error'
            })
        }
    }, [user.teams])

    const handleSubmitAddOrDeleteJobOffer = async (jobOfferId) => {
        try {
            const response = await addOrRemoveJobOffer(candidat._id, jobOfferId)

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            toast({
                title: 'Offre d\'emploi modifiée avec succès',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Erreur lors de la modification de l\'offre d\'emploi',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            fetchCandidat()
        }
    }

    const handleStatusChange = async (e) => {
        try {
            await updateCandidatStatus(candidat._id, e.target.value, setCandidatStatus)
            toast({
                title: 'Statut modifié.',
                status: 'success'
            })
        } catch (error) {
            toast({
                title: 'Erreur lors de la modification du statut',
                status: 'error'
            })
        }
    }



    if (!candidat) return null

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4} >
                    <Button as={Link} to="/candidats" size="sm" leftIcon={<FaArrowLeft />} colorScheme="brand" variant="ghost">Retour aux candidats</Button>
                </Flex>
                <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Détail du candidat</Heading>
                <Flex gap={4} animation={`${fadeIn} .4s ease-in`} direction={["column", "column", "column","column", "row"]} flexWrap={["nowrap", "nowrap", "nowrap", "nowrap", "wrap"]} >
                    <Flex direction={"column"} gap={4} flex={1}>
                        <Flex display={"flex"} direction={"column"}>
                            <Box w='100%' mb={4} bg="lightBg.50" borderRadius="xl" p={6} >
                                <Flex bgImage={`url(${candidateBg})`} bgSize={"1500px"} bgPosition={"center center"} height={"200px"} borderRadius="xl" justifyContent={"center"} alignItems={"end"}>
                                    <Avatar border={"4px solid white"} size={"xl"} name={`${candidat.firstname} ${candidat.lastname}`} src={candidat.blob} transform={"translateY(50%)"} />
                                </Flex>
                                <Flex pt={20} direction={"column"}>
                                    <Flex gap={4} alignItems={"center"} justifyContent="space-between">
                                        <Text fontSize='xl' as='b' fontWeight={"semibold"} color={"gray.700"}>{candidat.firstname.charAt(0).toUpperCase() + candidat.firstname.slice(1)} {candidat.lastname.toUpperCase()}</Text>
                                        <Flex flexDirection="column" alignItems="center" gap={1} >
                                            <Select height={"25px"} value={candidatStatus} onChange={handleStatusChange} bg={candidatStatus === 'Nouveau' ? 'dodgerblue' : candidatStatus === 'En cours' ? 'gold' : candidatStatus === 'Retenu' ? 'brand.500' : 'tomato'}>
                                                <option value='Nouveau'>Nouveau</option>
                                                <option value='En cours'>En cours</option>
                                                <option value='Retenu'>Retenu</option>
                                                <option value='Non retenu'>Non retenu</option>
                                            </Select>
                                        </Flex>
                                    </Flex>
                                    <Text fontSize='lg' color={"gray.700"}>{candidat.email}</Text>
                                    <Text fontSize='lg' color={"gray.700"}>{candidat.phone}</Text>
                                </Flex>
                            </Box>
                            <Flex px={8} py={8} gap={2} direction={"column"} w='100%' mb={4} bg="white" borderRadius="xl" p={6}>
                                <Flex alignItems={["flex-start", "center"]} justifyContent={"space-between"} direction={["column", "row"]}>
                                    <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Pièces jointes</Text>
                                    <Button size={"sm"} onClick={() => onOpenAddAttachment(true)}>Ajouter une pièce jointe</Button>
                                </Flex>
                                <Flex width={"100%"}>
                                    {
                                        attachments?.length === 0 ? (<EmptyAttachmentCard />) :
                                            (<AttachmentList attachments={attachments} reload={fetchCandidateAttachments} />)
                                    }
                                </Flex>
                            </Flex>
                            <Flex background="white" px={8} py={8} gap={2} mb={4} direction={"column"}>
                                <Flex alignItems={["flex-start", "center"]} justifyContent={"space-between"} direction={["column", "row"]}>
                                    <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Notes sur le candidat</Text>
                                    <Button size={"sm"} onClick={() => setIsOpen(true)}>Nouvelle note</Button>
                                </Flex>
                                <Grid gridTemplateColumns={"repeat(1, 1fr)"} gap={4} mt={4}>
                                    {candidat?.notes.length === 0 ? (
                                        <Flex p={4} color={"gray.600"} alignItems="center" gap={4}>
                                            <FaFileLines size={80} color="#03B97C" />
                                            <Text textAlign="left">Aucune note n'est associée à ce candidat</Text>
                                        </Flex>
                                    ) : null}
                                    {candidat?.notes.map((note, index) => {
                                        return <Flex key={index + "-quiz"} border={"1px solid #EDF2F7"} borderRadius="xl" p={4} direction="column">
                                            <Flex alignItems={"start"} justifyContent={"space-between"}>
                                                <Flex direction={"column"} flex={4}>
                                                    <Text fontWeight={"semibold"} color={"gray.800"} mb={2} fontSize='xl'>{note.title}</Text>
                                                    <Text fontSize='md'>{note.description}</Text>
                                                </Flex>
                                                <Flex gap={2} ml="auto" flex={1} justifyContent={"flex-end"}>
                                                    <Tooltip placement='top' label="Modifier la note">
                                                        <IconButton rounded="full" size={"sm"} icon={<FaEdit />} onClick={() => {
                                                            setUpdateNote(note._id)
                                                            setIsOpen(true)
                                                        }} />
                                                    </Tooltip>
                                                    <Tooltip placement='top' label="Supprimer la note">
                                                        <IconButton rounded="full" size={"sm"} colorScheme='red' icon={<FaTrash />} onClick={() => setDeleteNote(note._id)} />
                                                    </Tooltip>
                                                </Flex>
                                            </Flex>
                                            <Text mt={4} textAlign={"right"} fontSize={"sm"}>{note.createdAt === note.updatedAt ? `Créée le ${formatDateForPrint(note.createdAt)}` : `Modifiée le ${formatDateForPrint(note.updatedAt)}`}</Text>
                                        </Flex>
                                    })}
                                </Grid>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex flex={1} direction={"column"} borderRadius="xl" gap={4}>
                        <Flex background="white" borderRadius="xl" px={8} py={8} gap={2} direction={"column"}>
                            <Flex alignItems={["flex-start", "center"]} justifyContent={"space-between"} direction={["column", "row"]} mb={4}>
                                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Questionnaires en cours</Text>
                                <Button size={"sm"} onClick={() => setIsAddQuizVisible(true)}>Assigner un questionnaire</Button>
                            </Flex>
                            {runningQuiz && runningQuiz.length == 0 ? <EmptyRunningQuizCard /> : null}
                            <Grid gridTemplateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }} gap={4}>
                                {runningQuiz && runningQuiz.map((infos, index) => {
                                    const { quiz } = infos
                                    return <Box bg="lightBg.50" borderRadius="xl" p={4} key={index + "-running-quiz"}>
                                        <Flex justifyContent={"space-between"}>
                                            <Text as="b" fontSize='xl'>{quiz.name}</Text>
                                            <IconButton rounded="full" size={"sm"} colorScheme='red' icon={<FaTrash />} onClick={() => deleteRunningQuiz(infos._id)} />
                                        </Flex>
                                        <Text mt={4} fontSize='md'>{quiz.description}</Text>
                                        <Box mt={4}>
                                            {quiz.tags.map((tag, index) => (
                                                <Badge key={"tag-" + index} mr={1}>{tag}</Badge>
                                            ))}
                                        </Box>
                                        <Box textAlign={"right"} mt={4}>
                                            <Text as="i"><b>{quiz.questions.length}</b> questions</Text>
                                        </Box>
                                    </Box>
                                })}
                            </Grid>
                        </Flex>
                        <Flex background="white" borderRadius="xl" px={8} py={8} gap={2} direction={"column"}>
                            <Flex alignItems={["flex-start", "center"]} justifyContent={"space-between"} direction={["column", "row"]} mb={4}>
                                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Questionnaires réalisés</Text>
                            </Flex>
                            {doneQuiz && doneQuiz.length == 0 ? <EmptyDoneQuizCard /> : null}
                            <Grid gridTemplateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }} gap={4}>
                                {doneQuiz && doneQuiz.map((quiz, index) => {
                                    return <Flex key={index + "-quiz"} bg="lightBg.50" borderRadius="xl" p={4} direction="column">
                                        <Flex alignItems="center" justifyContent={"space-between"}>
                                            <Flex direction={"column"}>
                                                <Text as="b" fontSize='xl'>{quiz.name}</Text>
                                                <Text fontSize='md'>{quiz.description}</Text>
                                            </Flex>
                                            <Box>
                                                <CircularProgress value={(quiz.good / quiz.questions.length) * 100} color='green.400'>
                                                    <CircularProgressLabel>{Math.round((quiz.good / quiz.questions.length) * 100)}%</CircularProgressLabel>
                                                </CircularProgress>
                                            </Box>
                                        </Flex>
                                        <Flex flex={1} justifyContent={"flex-end"} mt={4} gap={1}>
                                            <Button onClick={() => updateRunningQuiz(quiz._id)} colorScheme='brand' variant="ghost" leftIcon={<IoRefresh />}>Proposer un nouvel essai</Button>
                                            <Button as={Link} to={"/exam/responses/" + quiz._id} colorScheme='brand' leftIcon={<FaFileSignature />}>Voir les résultats</Button>
                                        </Flex>
                                    </Flex>
                                })}
                            </Grid>
                        </Flex>
                        <CandidatAddQuiz isVisible={isAddQuizVisible} quizzes={quizzes} cancel={() => setIsAddQuizVisible(false)} updateRunningQuiz={updateRunningQuiz} runningQuiz={runningQuiz} reload={fetchRunningQuiz} />
                        <Flex background="white" borderRadius="xl" px={8} py={8} gap={2} direction={"column"}>
                            <Flex alignItems={"center"} justifyContent={"space-between"} mb={4}>
                                <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray.700"}>Offre d'emploi</Text>
                                <Button size={"sm"} onClick={() => onOpenAddJobOffer()}>Gérer les offres d'emplois</Button>
                            </Flex>

                            <Grid gridTemplateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)" }} gap={4}>
                                {(!candidat.jobsId || (candidat.jobsId && candidat.jobsId.length === 0)) && (
                                    <Flex p={4} color={"gray.600"} alignItems="center" gap={4}>
                                        <FaBriefcase size={80} color="#03B97C" />
                                        <Text textAlign="left">Aucune offre d'emploi n'est associée à ce candidat</Text>
                                    </Flex>
                                )}
                                {candidat.jobsId && candidat.jobsId.map((job, index) => {

                                    return <Flex bg="lightBg.50" borderRadius="xl" direction="column" px={4} py={4} key={"offre-" + index} >
                                        <Flex gap={2}>
                                            <Badge>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <FaMoneyBill /> {job.salary && job.salary[0]}€ - {job.salary && job.salary[1]}€
                                                </Flex>
                                            </Badge>
                                            <Badge>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <FaFileSignature /> {job.contractType}
                                                </Flex>
                                            </Badge>
                                            <Badge>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <FaBusinessTime /> {job.contractTime}
                                                </Flex>
                                            </Badge>
                                            <Badge marginLeft="auto">{displayOfferEndMessage(job.expirationDate)}</Badge>
                                        </Flex>
                                        <Flex gap={2} mt={2}>
                                            <Badge>
                                                <Flex alignItems={"center"} gap={2}>
                                                    <FaMapPin /> {job.location}
                                                </Flex>
                                            </Badge>
                                        </Flex>
                                        <Heading mt={6} mb={2}>{job.name}</Heading>
                                        <Text>{job.description}</Text>
                                        <Flex gap={2} mt={4} alignItems={"center"}>
                                            {job.tags.map((tag, index) => <Badge key={index}>
                                                {tag}
                                            </Badge>)}
                                            <Button as={Link} to={"/offre-emploi/" + job._id} marginLeft="auto" size="sm" colorScheme='brand' rightIcon={<FaArrowRight />}>Visualiser l'offre d'emploi</Button>
                                        </Flex>
                                    </Flex>
                                })}
                            </Grid>
                        </Flex>
                    </Flex>
                </Flex >
                <AddNoteDialogueComponent isOpen={isOpen} onClose={() => { setIsOpen(false); setUpdateNote(null) }} candidat={candidat} noteToUpdate={updateNote ? candidat.notes.find(note => note._id === updateNote) : null} reload={fetchCandidat} />
                <AlertDialog
                    isOpen={deleteNote}
                    onClose={() => setDeleteNote(null)}
                    isCentered
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Supprimer la note
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Etes-vous sur de vouloir supprimer cette note ?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button onClick={() => setDeleteNote(null)}>
                                    Annuler
                                </Button>
                                <Button colorScheme='red' onClick={() => {
                                    deleteCandidateNote(deleteNote)
                                    setDeleteNote(null)
                                }} ml={3}>
                                    Supprimer
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
                <AlertDialog
                    size={"xl"}
                    isOpen={isOpenAddJobOffer}
                    leastDestructiveRef={cancelRef}
                    onClose={() => {
                        onCloseAddJobOffer()
                    }}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Ajouter une offre d'emploi
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {jobOffers && candidat && candidat.jobsId && jobOffers.map((jobOffer, index) => {
                                    const isJobOfferAlreadyAdded = candidat.jobsId.map((job) => (job._id)).includes(jobOffer._id)

                                    return <React.Fragment key={index}>
                                        <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                            <Text fontSize='sm'>{jobOffer.name}</Text>
                                            {!isJobOfferAlreadyAdded && <Button rightIcon={<FaPlus />} size={"sm"} variant={"ghost"} color={"#03B97C"} onClick={async () => {
                                                await handleSubmitAddOrDeleteJobOffer(jobOffer._id);
                                            }}>Lier à cette offre</Button>}
                                            {isJobOfferAlreadyAdded && <Button rightIcon={<FaTrash />} size={"sm"} variant={"ghost"} color={"red"} onClick={async () => {
                                                await handleSubmitAddOrDeleteJobOffer(jobOffer._id, true);
                                            }}>Supprimer de cette offre</Button>}
                                        </Box>
                                        <Divider />
                                    </React.Fragment>
                                })}
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={() => {
                                        onCloseAddJobOffer()
                                    }}>
                                        Fermer
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogBody>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
                <AddAttachmentDialog isOpen={isOpenAddAttachment} onClose={onCloseAddAttachment} cancelRef={cancelRefAttachment} candidat={candidat} reload={fetchCandidat} />
            </Container >
        </>
    )
}

export default CandidatComponent
