import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaFolderOpen } from 'react-icons/fa'

const EmptyAttachmentCard = ({ isCandidate }) => {
    return (
        <Flex p={4} color={"gray.600"} alignItems="center" gap={4} bg={"white"} boxShadow={isCandidate ? "base" : null}>
            <FaFolderOpen size={80} color={"#03B97C"} />
            <Text>{isCandidate ? "Vous n'avez pas de pièce jointes !" : "Aucune pièce jointe n'est disponible pour ce candidat"}</Text>
        </Flex>
    )
}

export default EmptyAttachmentCard