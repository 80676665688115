import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NavbarLinkItem = ({ logo, text, link, isActive, largerThanLg, isExpanded, isMobile }) => {

    const [fullTextBar, setFullTextBar] = useState(true)

    useEffect(() => {
        if (largerThanLg && isExpanded) {
            setFullTextBar(true);
        } else if (isMobile) {
            setFullTextBar(true);
        } else {
            setFullTextBar(false);
        }
    }, [largerThanLg, isExpanded, isMobile]);

    return (
        <Tooltip label={!fullTextBar ? text : ""} placement='right'>
            <Flex
                justifyContent={"start"}
                w={fullTextBar ? "100%" : "fit-content"}
                h={isMobile ? 39 : "18%"}
                alignItems={"center"}
                gap={4}
                bg={isActive ? "brand.500" : null}
                p={3}
                borderRadius={fullTextBar ? 8 : 50}
                transition={"0.2s"}
                _hover={{ bg: "brand.500", color: "white" }}
                cursor={"pointer"}
                as={Link}
                to={link}
            >
                <Box bg={isActive && isMobile  ? "" : isMobile ? null : "brand.500"} p={3} borderRadius={"100%"}>
                    {logo}
                </Box>
                <Text
                    fontWeight="semibold"
                    fontSize="md"
                    color={isActive ? "white" : "gray.600"}
                    display={fullTextBar ? "flex" : "none"}
                >{text}</Text>
            </Flex>
        </Tooltip>
    )
}

export default NavbarLinkItem