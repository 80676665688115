import csrfFetch from "../Utils/CSRF";

export const getStatistics = async (setData) => {
    const response = await csrfFetch('/statistiques/');

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    if (response.status === 200) {
        const data = await response.json();
        setData(data)
    }
}