import { Avatar, Flex, IconButton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React from 'react'
import { FaListUl, FaPerson } from 'react-icons/fa6'
import { FiCheckSquare } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { formatDateForPrint } from '../../Utils/FormatDate'
import HomepageEmptyTable from '../HomepageEmptyTable'
import { fadeIn } from './Animation'
import { useNavigate } from 'react-router-dom'

const ListCandidatDoneQuiz = ({ statistiques }) => {

    const navigate = useNavigate()
    
    return (
        <Flex
            flex={1}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            borderRadius={"xl"}
            animation={`${fadeIn} .4s ease-in`}
        >
            {statistiques && statistiques.doneQuiz && statistiques.doneQuiz.length === 0 ? <HomepageEmptyTable text={"Les questionnaires en terminés de vos candidats apparaîtront ici"} /> : null}
            {statistiques && statistiques.doneQuiz && statistiques.doneQuiz.length > 0 ? (
                <Flex>
                    <TableContainer flex={1} mt={4} overflowX={{ base: "scroll", md: "auto" }}>
                        <Table variant='unstyled' size={"sm"}>
                            <Thead>
                                <Tr>
                                    <Th color={"gray.600"} pb={4} />
                                    <Th color={"gray.600"} pb={4}>Nom</Th>
                                    <Th color={"gray.600"} pb={4}>Prénom</Th>
                                    <Th color={"gray.600"} pb={4}>Titre du questionnaire</Th>
                                    <Th color={"gray.600"} pb={4}>Date d'assignation</Th>
                                    <Th color={"gray.600"} pb={4}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody borderTop="1px solid #E2E8F0">

                                {statistiques && statistiques.doneQuiz && statistiques.doneQuiz.map((infos, index) => {
                                    return <Tr key={index}
                                        // borderBottom={index === statistiques.doneQuiz.length - 1 ? "none" : "1px solid #E2E8F0"}
                                        cursor={"pointer"} _hover={{ bg: "gray.100" }}
                                        color={"gray.700"} fontWeight={"semibold"}>
                                        <Td width={"10px"} onClick={() => { navigate('/candidat/' + candidate._id) }}>
                                            <Avatar size='sm' name={infos.candidateId.firstname + ' ' + infos.candidateId.lastname} src={infos.candidateId.picture} />
                                        </Td>
                                        <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{infos.candidateId.lastname}</Td>
                                        <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{infos.candidateId.firstname}</Td>
                                        <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{infos.name}</Td>
                                        <Td onClick={() => { navigate('/candidat/' + candidate._id) }}>{formatDateForPrint(infos.assignation)}</Td>
                                        <Td>
                                            <Flex gap={1}>
                                                <Tooltip label='Voir le profil du candidat'>
                                                    <IconButton rounded={"full"} as={Link} to={`/candidat/${infos.candidateId._id}`} size={"sm"} icon={<FaPerson />} colorScheme='brand' />
                                                </Tooltip>
                                                <Tooltip label='Voir les résultats du candidat'>
                                                    <IconButton rounded={"full"} as={Link} to={`/exam/responses/${infos._id}`} size={"sm"} icon={<FiCheckSquare />} colorScheme='brand' />
                                                </Tooltip>
                                                <Tooltip label='Voir le questionnaire'>
                                                    <IconButton rounded={"full"} as={Link} to={`/questionnaire/${infos.quiz._id}`} size={"sm"} icon={<FaListUl />} colorScheme='brand' />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                    </Tr>

                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>) : null}
        </Flex>
    )
}

export default ListCandidatDoneQuiz
