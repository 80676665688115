import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { handleFormErrors } from '../../Utils/HandleFormErrors';
import { createTeam } from '../../API/Teams';

const ModalAddTeam = ({ isOpen, cancelRef, onClose, reload }) => {

    const [newTeamInfos, setNewTeamInfos] = useState({ companyName: "", name: "", description: "" })
    const [errors, setErrors] = useState({});
    const toast = useToast();

    const handleTeamCreation = async () => {
        try {
            const response = await createTeam({ ...newTeamInfos });
            const data = await response.json();

            if (!response.ok) {
                handleFormErrors(setErrors, data.errors);

                throw new Error('Failed to fetch data');
            }

            toast({
                title: "Votre équipe a bien été créée"
            })
            setNewTeamInfos({ companyName: "", name: "", description: "" });
            reload()
            onClose()
        } catch (error) {
            toast({
                title: "Impossible de créer l'équipe.",
                status: "error",
            })
        }
    }


    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Créer une nouvelle équipe
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <FormControl isInvalid={errors && errors.companyName} mb={4}>
                            <FormLabel>Nom de votre entreprise</FormLabel>
                            <Input placeholder='Choisisez un nom pour votre entreprise' required value={newTeamInfos.companyName} onChange={event => setNewTeamInfos({ ...newTeamInfos, companyName: event.target.value })} />
                            {errors && errors.companyName && <FormErrorMessage>{errors.companyName}</FormErrorMessage>}
                        </FormControl>

                        <FormControl isInvalid={errors && errors.name} mb={4}>
                            <FormLabel>Nom de votre équipe</FormLabel>
                            <Input placeholder='Choisisez un nom pour votre équipe' required value={newTeamInfos.name} onChange={event => setNewTeamInfos({ ...newTeamInfos, name: event.target.value })} />
                            {errors && errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
                        </FormControl>

                        <FormControl isInvalid={errors && errors.description} mb={4}>
                            <FormLabel>Description de votre équipe</FormLabel>
                            <Textarea placeholder='Choisisez un nom pour votre équipe' required value={newTeamInfos.description} onChange={event => setNewTeamInfos({ ...newTeamInfos, description: event.target.value })} />
                            {errors && errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                        </FormControl>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Flex gap={2}>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='brand' ref={cancelRef} onClick={() => handleTeamCreation()}>
                                Créer mon équipe
                            </Button>
                        </Flex>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ModalAddTeam
