import csrfFetch from '../Utils/CSRF';

export const getFileByKey = async (key) => {
    return await csrfFetch('/s3/get', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key })
    });
}