import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React, { useRef } from 'react'

const TableDeletionDialog = ({ isOpen, setToDeletion, toDeletion, onClose, handleDeletion }) => {
    const cancelRef = useRef()

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {
                setToDeletion(null)
                onClose()
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Suppression de l'offre d'emploi
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Êtes-vous sûr de vouloir supprimer l'offre d'emploi <b>{toDeletion}</b>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button colorScheme='red' onClick={() => {
                            handleDeletion()
                            onClose()
                        }} ml={3}>
                            Supprimer
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default TableDeletionDialog