import { Badge, Card, CircularProgress, Flex, Heading, Tag, Text } from '@chakra-ui/react';
import { Circle, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBusinessTime, FaFileSignature, FaLaptop, FaMapPin, FaMoneyBill } from "react-icons/fa6";
import { GOOGLE_MAPS_KEY } from "../../keys";
import { displayOfferEndMessage, formatDateForPrint } from '../../Utils/FormatDate';


const JobOfferHeader = ({ tags, location, contractType, contractTime, salary, remote, expirationDate, publicationDate }) => {
    const [map, setMap] = useState(null)
    const [center, setCenter] = useState(null);

    // const toast = useToast();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_KEY
    })

    const onLoad = useCallback(async (map) => {
        if (!location) {
            return;
        }
        const geocoder = new window.google.maps.Geocoder();
        const { results } = await geocoder.geocode({ 'address': location });

        if (!results || results.length === 0) {
            return;
        }

        const geoLocation = results[0].geometry.location;

        setCenter(new window.google.maps.LatLng(geoLocation.lat(), geoLocation.lng()));


        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map);
    }, [location]);

    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])

    const containerStyle = {
        width: '500px',
        height: '500px',
    };


    return (
        <Flex bg="lightBg.50" borderRadius={"xl"} p={4} pt={2} color="gray.700">
            <Flex mt={6} alignItems="baseline" gap={4} flexFlow={"column wrap"}>
                <Heading as='h2' size='md'>Détail de l'offre</Heading>
                <Flex flexFlow={"row wrap"} gap={2}>
                    {location && <Tag colorScheme='gray'><Flex gap={2}><FaMapPin />{location}</Flex></Tag>}
                    {remote && <Tag colorScheme='gray'><Flex gap={2}><FaLaptop />{remote}</Flex></Tag>}
                    {contractType && <Tag colorScheme='gray'><Flex gap={2}><FaFileSignature />{contractType}</Flex></Tag>}
                    {contractTime && <Tag colorScheme='gray'><Flex gap={2}><FaBusinessTime />{contractTime}</Flex></Tag>}
                    {salary && <Tag colorScheme='gray'><Flex gap={2}><FaMoneyBill />{salary && salary[0]}€ - {salary && salary[1]}€</Flex></Tag>}
                </Flex>
                <Heading mt={4} as='h2' size='md'>Date de l'offre</Heading>
                <Flex flexFlow={"column wrap"} gap={2} alignItems={"flex-start"}>
                    <Flex alignItems={"center"} gap={2}>
                        <Text>État de l'offre : </Text>
                        {!(new Date(publicationDate) < new Date()) ? <Badge>Publication prévue</Badge> : expirationDate ? <Badge>{displayOfferEndMessage(expirationDate)}</Badge> : <Badge>En ligne</Badge>}
                    </Flex>
                    <Flex alignItems={"center"} gap={2}>
                        <Text>Publication de l'offre :</Text>
                        <Text><Badge>{formatDateForPrint(publicationDate)}</Badge></Text>
                    </Flex>
                    {
                        expirationDate &&
                        <Flex alignItems={"center"} gap={2}>
                            <Text>Fermeture de l'offre :</Text>
                            <Text><Badge>{formatDateForPrint(expirationDate)}</Badge></Text>
                        </Flex>
                    }
                </Flex>
                {tags && tags.length > 0 &&
                    <>
                        <Heading mt={4} as='h2' size='md'>Compétences</Heading>
                        <Flex flexFlow={"row wrap"} gap={2}>
                            {tags?.map(tag => <Tag key={tag} colorScheme='gray'>{tag}</Tag>)}
                        </Flex>
                    </>
                }
                {location !== undefined && location !== "" &&
                    <>
                        <Heading mt={4} as='h2' size='md'>Localisation</Heading>
                        <Flex flexFlow={"row wrap"} gap={2}>
                            {isLoaded ? <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} onLoad={onLoad} onUnmount={onUnmount}>
                                <Circle
                                    center={center}
                                    radius={5000} // Rayon du cercle en mètres
                                    options={{
                                        strokeColor: '#FF0000',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: '#FF0000',
                                        fillOpacity: 0.35,
                                    }}
                                />
                            </GoogleMap> : <CircularProgress isIndeterminate color='green.300' />}
                        </Flex>
                    </>
                }
            </Flex>
        </Flex>
    )
}

export default JobOfferHeader