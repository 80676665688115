import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Container, Flex, Heading, Input, useToast } from '@chakra-ui/react';
import { FaPlusSquare } from 'react-icons/fa';
import { getLoggedJobOffer } from '../API/JobOffers';
import { fadeIn } from '../Components/Dashboard/Animation';
import JobOffersTable from '../Components/JobOffers/JobOffersTable';

const JobOfferUpdateComponent = () => {
    const [jobOffers, setJobOffers] = useState([])
    const [searchBar, setSearchBar] = useState("")

    const toast = useToast()

    useEffect(() => {
        loadUserJobOffers()
    }, [])

    const loadUserJobOffers = async () => {
        try {
            await getLoggedJobOffer(setJobOffers)
        } catch (error) {
            toast({
                title: "Erreur lors de la récupération des offres d'emploi",
                status: "error",
            });
            setJobOffers([])
        }
    }

    return (
        <>
            <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Offres d'emploi</Heading>
            <Container maxW="full" p={4}>
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`}>
                    <Flex gap={4} justifyContent={"space-between"} alignItems={"center"}>
                        <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Vos offres d'emploi</Heading>
                        <Flex gap={4}>
                            <Button as={Link} to="/offre-emploi/update/new" leftIcon={<FaPlusSquare />} size="sm" colorScheme="brand" variant="ghost">
                                Créer une offre d'emploi
                            </Button>
                        </Flex>
                    </Flex>
                    <Box my={4}>
                        <Input variant='flushed' placeholder="Rechercher une offre d'emploi" value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                    </Box>
                    <Box w='100%' borderRadius={"md"}>
                        <JobOffersTable jobOffers={jobOffers.filter(row => row.name.toLowerCase().includes(searchBar.toLowerCase()) || row.content.toLowerCase().includes(searchBar.toLowerCase()))} reload={loadUserJobOffers} />
                    </Box>
                </Flex>
            </Container>
        </>
    )
}

export default JobOfferUpdateComponent