import { Alert, AlertIcon, Avatar, Box, Button, Container, Flex, Grid, Heading, IconButton, Input, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Skeleton, SkeletonCircle, Tag, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useContext, useEffect, useRef, useState } from 'react'
import { FaPlusSquare, FaSignOutAlt } from "react-icons/fa"
import { FaEllipsis, FaPen } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { logout, updateProfilPicture } from "../API/Users"
import { UserContext } from '../Authentication/ProtectedRoute'
import { fadeIn } from '../Components/Dashboard/Animation'
import DrawerUpdateProfil from '../Components/Profile/DrawerUpdateProfil'
import TeamCard from '../Components/Profile/TeamCard'
import ProfileUpdateTeam from '../Components/ProfileUpdateTeam'
import profileBg from "../Resources/Images/profile_bg.jpg"
import CopyToClipboard from '../Utils/CopyToClipboard'
import ModalAddTeam from '../Components/Profile/ModalAddTeam'

const ProfileComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [team, setTeam] = useState(null);
    const { user, loadData } = useContext(UserContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenModalAddTeam, onOpen: onOpenModalAddTeam, onClose: onCloseModalAddTeam } = useDisclosure()
    const cancelModelAddTeam = useRef()
    const btnRef = useRef()
    const [isHovered, setIsHovered] = useState(false);

    const copyIdentifiant = async () => {
        let status = await CopyToClipboard(user._id);
        if (status) {
            toast({
                title: "Presse papier.",
                description: `Votre identifiant a bien été copié.`,
            });
        } else {
            toast({
                title: "Presse papier.",
                description: `Votre identifiant n'a pas pu être copié.`,
                status: "error",
            });
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target?.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append("file", file);
                let res = await updateProfilPicture(formData);
                if (res.status === 500) {
                    res = await res.json();
                    toast({
                        title: res.message,
                        status: "error",
                    });
                } else {
                    toast({
                        title: "Votre image de profil a été mise à jour",
                    });
                }
                loadData()
            } catch (error) {
                toast({
                    title: "Erreur lors de la mise à jour de l'image de profil",
                    status: "error",
                });
            }
        }
    };

    const handleLogout = async () => {
        logout();
        navigate("/login");
    };

    const closeDrawer = () => {
        setIsVisible(false);
        setTeam(null);
    };

    const retriveAccountInfos = {
        to: "aptitude.recrutement@gmail.com",
        subject: "Récupération des informations de mon compte",
        text: `Bonjour je suis ${user.firstname} ${user.lastname}, avec pour identifiant ${user._id}. \n
          Je souhaite recevoir dans les plus brefs délais toutes les informations liés à mon compte disponible sur l'application Aptitude. \n
          Cordialement.`,
    };

    const deletingAccountInfos = {
        to: "aptitude.recrutement@gmail.com",
        subject: "Suppression des informations de mon compte",
        text: `Bonjour je suis ${user.firstname} ${user.lastname}, avec pour identifiant ${user._id}. \n
          Je souhaite recevoir dans les plus brefs délais toutes les informations liés à mon compte disponible sur l'application Aptitude. \n
          Cordialement.`,
    };

    let isUserLoaded = !(!user.firstname && !user.lastname)

    return (
        <Container maxW="full" p={[2, 4]}>
            <Heading p={[2, 4]} pb={0} size="md" mb={4} color="gray.600" fontWeight="semibold">
                Mon Profil
            </Heading>
            <Flex
                bgImage={`url(${profileBg})`}
                backgroundSize="cover"
                borderRadius="2xl"
                height={["150px", "250px"]}
                px={[4, 14]}
                mb="120px"
                direction="column"
                justifyContent="space-between"
                animation={`${fadeIn} .4s ease-in`}
            >
                {
                    <Tag ml="auto" mt={4} cursor="pointer" background="gray.100" _hover={{ bg: "gray.200" }} fontSize="xs" onClick={copyIdentifiant} width="fit-content">
                        <Skeleton isLoaded={isUserLoaded}>
                            {user._id}
                        </Skeleton>
                    </Tag>
                }
                <Flex background="gray.50" border="2px solid white" borderRadius="2xl" p={[4, 8]} transform="translateY(50%)" alignItems={{ base: "baseline", md: "center" }} direction={{ base: "column", md: "row" }} >
                    <Flex alignItems="center" gap={4} >
                        <Tooltip label="Modifier votre photo de profil." placement="top">
                            <Box
                                borderRadius={"full"}
                                position="relative"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                _hover={{ "&:before": { content: `""`, position: "absolute", zIndex: 10, top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.4)", borderRadius: "100%", cursor: "pointer" } }}
                            >
                                <Skeleton borderRadius={"full"} isLoaded={isUserLoaded}>
                                    <Avatar zIndex={1} size="lg" name={`${user.firstname} ${user.lastname}`} src={user.blob} />
                                </Skeleton>
                                <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex={20} alignItems="center" justifyContent="center" borderRadius="full" p={2} cursor="pointer">
                                    {isHovered ? <IconButton type="file" accept="image/*" variant="ghost" rounded="full" icon={<FaPen color="white" />} _hover={{ bg: "rgba(0,0,0,0)" }} /> : null}
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        width="100%"
                                        height="100%"
                                        opacity="0"
                                        cursor="pointer"
                                        zIndex="1"
                                    />
                                </Box>
                            </Box>
                        </Tooltip>
                        <Flex direction="column" align={["center", "start"]} gap={1}>
                            <Skeleton isLoaded={isUserLoaded}>
                                <Text color="gray.600" fontSize={["lg", "2xl"]} fontWeight="semibold">
                                    {user.firstname} {user.lastname?.toUpperCase()}
                                </Text>
                            </Skeleton>
                            {user.phone && <Text color="gray.600" fontSize="lg" fontWeight="semibold">{user.phone}</Text>}
                            <Skeleton isLoaded={isUserLoaded}>
                                <Text color="gray.600" fontSize="lg" fontWeight="semibold">
                                    {user.email}
                                </Text>
                            </Skeleton>
                        </Flex>
                    </Flex>
                    <Flex mt={[4, 0]} ml={[0, "auto"]} gap={2} alignItems="center">
                        <Popover>
                            <PopoverTrigger>
                                <IconButton borderRadius="full" icon={<FaEllipsis />} />
                            </PopoverTrigger>
                            <PopoverContent width="max-content">
                                <PopoverCloseButton />
                                <PopoverHeader>Options supplémentaires</PopoverHeader>
                                <PopoverBody>
                                    <Flex direction="column" gap={2}>
                                        <Button as="a" href={`mailto:${retriveAccountInfos.to}?subject=${encodeURIComponent(retriveAccountInfos.subject)}&body=${encodeURIComponent(retriveAccountInfos.text)}`}>
                                            Demander la récupération de mes informations
                                        </Button>
                                        <Button as="a" href={`mailto:${deletingAccountInfos.to}?subject=${encodeURIComponent(deletingAccountInfos.subject)}&body=${encodeURIComponent(deletingAccountInfos.text)}`} colorScheme="red">
                                            Demander la suppression de mon compte
                                        </Button>
                                    </Flex>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                        <Tooltip label="Éditer mon profil">
                            <IconButton rounded="full" ref={btnRef} borderRadius="full" icon={<FaPen />} onClick={() => onOpen()} />
                        </Tooltip>
                        <Tooltip label="Se déconnecter">
                            <IconButton rounded="full" colorScheme="red" borderRadius="full" icon={<FaSignOutAlt />} onClick={handleLogout} />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>
            <Flex alignItems={"center"} mb={4} gap={4}>
                <Heading p={[2, 4]} pb={0} size="md" color="gray.600" fontWeight="semibold">
                    Vos équipes
                </Heading>
                <Button leftIcon={<FaPlusSquare />} onClick={onOpenModalAddTeam} size="sm" colorScheme="brand" variant="ghost">
                    Ajouter une équipe
                </Button>
            </Flex>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={4} animation={`${fadeIn} 0.6s ease-in`}>
                {user?.teams?.map((teamInfo, index) => (
                    <TeamCard index={index} setIsVisible={setIsVisible} setTeam={setTeam} key={index} {...teamInfo} hideList={user.role === "candidate"} />
                ))}
            </Grid>
            <DrawerUpdateProfil isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
            <ProfileUpdateTeam isVisible={isVisible} onClose={closeDrawer} team={team} />
            <ModalAddTeam isOpen={isOpenModalAddTeam} onClose={onCloseModalAddTeam} cancelRef={cancelModelAddTeam} reload={loadData} />
        </Container>
    );
};

export default ProfileComponent;