import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaFileSignature } from 'react-icons/fa'

const EmptyRunningQuizCard = ({ isCandidate }) => {
    return (
        <Flex p={4} color={"gray.600"} alignItems="center" gap={4} bg={"white"}>
            <FaFileSignature size={80} color={"#03B97C"} />
            <Text>{isCandidate ? "Vous n'avez pas de questionnaires à faire !" : "Aucun questionnaire est en cours pour ce candidat"}</Text>
        </Flex>
    )
}

export default EmptyRunningQuizCard