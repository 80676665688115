import React, { useEffect } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Flex, IconButton, Tooltip, Avatar, Text } from '@chakra-ui/react'
import { Link } from "react-router-dom"
import { formatDateForPrint } from '../../Utils/FormatDate'
import { FaListUl, FaPerson } from 'react-icons/fa6'
import { fadeIn } from './Animation'
import HomepageEmptyTable from '../HomepageEmptyTable'
import { useNavigate } from 'react-router-dom'

const ListRunningQuiz = ({ statistiques }) => {

    const navigate = useNavigate()

    return (
        <Flex
            flex={1}
            gridArea={"2 / 3 / 4 / 6"}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            animation={`${fadeIn} .4s ease-in`}
        >

            {statistiques && statistiques.runningQuiz && statistiques && statistiques.runningQuiz.length === 0 ? <HomepageEmptyTable text={"Les questionnaires en cours de vos candidats apparaîtront ici"} /> : null}
            {statistiques && statistiques.runningQuiz && statistiques && statistiques.runningQuiz.length > 0 ? (<Flex>
                <TableContainer flex={1} overflowX={{ base: "scroll", md: "auto" }}>
                    <Table variant='unstyled' size={"sm"}>
                        <Thead>
                            <Tr>
                                <Th color={"gray.600"} p={4} />
                                <Th color={"gray.600"} p={4}>Nom</Th>
                                <Th color={"gray.600"} p={4}>Prénom</Th>
                                <Th color={"gray.600"} p={4}>Titre du questionnaire</Th>
                                <Th color={"gray.600"} p={4}>Date d'assignation</Th>
                                <Th color={"gray.600"} p={4}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody borderTop="1px solid #E2E8F0">
                            {statistiques && statistiques.runningQuiz && statistiques.runningQuiz.map((infos, index) => {
                                return <Tr key={index}
                                    cursor={"pointer"} _hover={{ bg: "gray.100" }}
                                    // borderBottom={index ===  statistiques.runningQuiz.length - 1 ? "none" : "1px solid #E2E8F0"}
                                    color={"gray.700"} fontWeight={"semibold"}>
                                    <Td width={"10px"} onClick={() => { navigate('/candidat/' + infos.candidateId._id) }}>
                                        <Avatar size='sm' name={infos.candidateId.firstname + ' ' + infos.candidateId.lastname} src={infos.candidateId.blob} />
                                    </Td>
                                    <Td onClick={() => { navigate('/candidat/' + infos.candidateId._id) }}>{infos.candidateId.lastname}</Td>
                                    <Td onClick={() => { navigate('/candidat/' + infos.candidateId._id) }}>{infos.candidateId.firstname}</Td>
                                    <Td onClick={() => { navigate('/questionnaire/' + infos.quiz._id) }}>{infos.quiz.name}</Td>
                                    <Td onClick={() => { navigate('/questionnaire/' + infos.quiz._id) }}>{formatDateForPrint(infos.createdAt)}</Td>
                                    <Td>
                                        <Tooltip label='Voir le questionnaire'>
                                            <IconButton rounded="full" as={Link} to={`/questionnaire/${infos.quiz._id}`} size={"sm"} icon={<FaListUl />} colorScheme='brand' />
                                        </Tooltip>
                                    </Td>
                                </Tr>

                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>) : null}
        </Flex>
    )
}

export default ListRunningQuiz
