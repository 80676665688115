import { Tag } from "@chakra-ui/react"

export const convertTypeToLabel = (type) => {
    if (type === "public") {
        return <Tag colorScheme="blue">Publique</Tag>
    } else if (type === "private") {
        return <Tag colorScheme="purple">Privée</Tag>
    } else {
        return <Tag colorScheme="gray">Aucune</Tag>
    }
}

export const GetLabelFromType = (type) => {
    if (type === "unique") {
        return "Choix unique"
    } else if (type === "multiple") {
        return "Choix multiple"
    } else if (type === "short") {
        return "Réponse courte"
    } else {
        return "Aucun"
    }
}