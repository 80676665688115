import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Container, Flex, FormControl, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import { FaPlusSquare } from 'react-icons/fa';
import { FaFileImport } from 'react-icons/fa6';
import { getGoogleAuthUrl } from '../API/Oauth';
import { createFromGoogle, loadUserQuizzes } from "../API/Quizzes";
import { getTeamByUser } from '../API/Teams';
import { UserContext } from '../Authentication/ProtectedRoute';
import { fadeIn } from '../Components/Dashboard/Animation';
import QuizzesTable from '../Components/QuizzesTable';

const QuizzesComponent = () => {
    const { user } = useContext(UserContext)
    const [quizzes, setQuizzes] = useState([])
    const [searchBar, setSearchBar] = useState("")
    const [googleForms, setGoogleForms] = useState('')
    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useState([])
    const [team, setTeam] = useState('')
    const [visibility, setVisibility] = useState('public')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    useEffect(() => {
        try {
            loadUserQuizzes(setQuizzes)
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des questionnaires',
                status: 'error'
            })
        }
        fetchUserTeam()
    }, [])

    const fetchUserTeam = async () => {
        try {
            const data = await getTeamByUser();
            setTeam(data[0]?._id)
            setTeams(data)
        } catch (error) {
            toast({
                title: 'Erreur lors du chargement des équipes',
                status: 'error'
            })
        }
    }

    const handleVisibility = (e) => {
        setVisibility(e);
    }

    const loadFromGoogle = async () => {
        setLoading(true);
        const request = await createFromGoogle({ googleForms, team, visibility });

        if (!request.ok) {
            setLoading(false);
            toast({
                title: 'Une erreur est survenue.',
                status: 'error',
            })
            return;
        }

        setLoading(false);
        loadUserQuizzes(setQuizzes);
        setGoogleForms('');
        onClose();
        toast({
            title: 'Questionnaires importés.'
        })
    }

    const googleLogin = async () => {
        const url = await getGoogleAuthUrl();
        window.location.href = url
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Importer depuis Google</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                        {!user.google ?
                            <>
                                <Text textAlign={"justify"}>Vous devez vous connecter pour importer des Google Form</Text>
                                <Button textAlign={"center"} colorScheme={"teal"} mt={4} onClick={googleLogin}>Se connecter pour importer des Google Form</Button>
                            </>

                            : <>
                                <Flex gap={4} direction={{ base: "column", lg: "row" }} flexWrap="wrap">
                                    <FormControl flex={1}>
                                        <FormLabel>Visibilité</FormLabel>

                                        <RadioGroup value={visibility || 'public'} onChange={(e) => handleVisibility(e)} >
                                            <Stack spacing={4} direction='row'>
                                                <Radio colorScheme='green' value='public'>
                                                    Publique
                                                </Radio>
                                                <Radio colorScheme='green' value='private'>
                                                    Privée
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl flex={1}>
                                        <FormLabel>Équipe propriétaire</FormLabel>
                                        <Select spacing={5} onChange={(e) => setTeam(e.target.value)}>
                                            {teams && teams.length > 0 && teams.map((team, index) => <option key={"team" + index} value={team._id}>{team.name}</option>)}
                                        </Select>
                                    </FormControl>
                                </Flex>
                                <Textarea mt={4} value={googleForms} placeholder="Listez vos Google Form ici, un par ligne" onChange={e => setGoogleForms(e.target.value)}></Textarea>
                            </>
                        }
                    </ModalBody>

                    <ModalFooter display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>

                        {user.google &&
                            <>
                                <Button variant={'ghost'} colorScheme='blue' onClick={onClose}>
                                    Fermer
                                </Button>
                                <Button
                                    isLoading={loading}
                                    colorScheme="brand"
                                    onClick={() => loadFromGoogle()}
                                    disabled={loading}
                                >
                                    {loading ? 'Importation en cours...' : 'Importer'}
                                </Button>
                            </>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal >
            <Heading p={4} pb={0} size={"md"} mb={4} color={"gray.600"} fontWeight={"semibold"}>Questionnaires</Heading>
            <Container maxW="full" p={4}>
                <Flex bg="white" direction="column" gap={2} p={8} borderRadius={"xl"} animation={`${fadeIn} .4s ease-in`}>
                    <Flex gap={4} justifyContent={"space-between"} alignItems={"center"}>
                        <Heading p={4} pb={0} pl={0} size={"lg"} color={"gray.700"} fontWeight={"semibold"}>Vos questionnaires</Heading>
                        <Flex gap={4}>
                            <Button as={Link} to="/questionnaire/nouveau" leftIcon={<FaPlusSquare />} size="sm" colorScheme="brand" variant="ghost">
                                Créer un questionnaire
                            </Button>
                            <Button leftIcon={<FaFileImport />} onClick={onOpen} size="sm" colorScheme="brand" variant="ghost">
                                Importer des questionnaires
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex my={4} alignItems={"end"} gap={8}>
                        <Input variant='flushed' placeholder='Rechercher un questionnaire' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                    </Flex>
                    <Box w='100%' borderRadius={"md"}>
                        <QuizzesTable loading={loading} quizzes={quizzes.filter(row => row.name.toLowerCase().includes(searchBar.toLowerCase()) || row.description.toLowerCase().includes(searchBar.toLowerCase()))} reload={() => loadUserQuizzes(setQuizzes)} />
                    </Box>
                </Flex>
            </Container>
        </>
    )
}

export default QuizzesComponent