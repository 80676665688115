import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    IconButton,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaTrash } from "react-icons/fa6";
import EmptyCandidates from "../Resources/Images/empty_candidates.svg";
import PaginationTable from './PaginationTable';
import { deleteInvitation } from "../API/Invitations";
import { useSortableData } from './useSortableData';

const PAGINATION_SIZE = 10;

const InvitationsTable = ({ invitations, reload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const [pageIndex, setPageIndex] = useState(0);
    const [paginationData, setPaginationData] = useState([]);
    const toast = useToast()

    const { items: sortedInvitations, requestSort, getSortDirection } = useSortableData(invitations);

    useEffect(() => {
        const paged = sortedInvitations.slice(
            pageIndex * PAGINATION_SIZE,
            (pageIndex + 1) * PAGINATION_SIZE
        )

        setPaginationData(paged)
    }, [pageIndex, sortedInvitations])

    const handleDeletion = async () => {
        try {
            const response = await deleteInvitation(toDeletion._id);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else {
                reload()
                toast({
                    title: "Invitation supprimée"
                })
            }

        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression de l\'invitation',
                status: 'error',
            })
        }
    }

    return paginationData.length === 0 ? <Flex justifyContent={"center"}>
        <Box p={4}>
            <Image src={EmptyCandidates} alt="No candidates" maxHeight={500} />
            <Text textAlign={"center"} fontSize={"md"} color={"gray.600"}>Il n'y a acune invitations en attente pour le moment</Text>
        </Box>
    </Flex> : (
        <>
            <TableContainer width={"100%"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr userSelect={"none"}>
                            <Th onClick={() => requestSort('email')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Email</Text>{getSortDirection('email')}
                                </Flex>
                            </Th>
                            <Th onClick={() => requestSort('team.name')} cursor={"pointer"}>
                                <Flex alignItems={"center"} gap={2}>
                                    <Text>Équipe</Text>{getSortDirection('team.name')}
                                </Flex>
                            </Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginationData.map((invitation, index) => (
                            <Tr key={index}>
                                <Td>{invitation.email}</Td>
                                <Td>{invitation.team.name}</Td>
                                <Td>
                                    <Tooltip label="Supprimer" placement='top'>
                                        <IconButton
                                            rounded="full"
                                            onClick={() => {
                                                setToDeletion(invitation)
                                                onOpen()
                                            }}
                                            colorScheme='red'
                                            aria-label='Delete'
                                            icon={<FaTrash />}
                                        />
                                    </Tooltip>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <PaginationTable pageSize={PAGINATION_SIZE} pageIndex={pageIndex} setPageIndex={setPageIndex} totalItemsCount={sortedInvitations.length} showQuantity={true} />
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression d'invitation
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer l'invitation pour <b>{toDeletion && toDeletion.email}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog >
        </>
    )
}

export default InvitationsTable