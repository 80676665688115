import { Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

const ViewAttachmentModel = ({ isOpen, onClose, finalRef, attachment }) => {
    if (!attachment) {
        return null
    }

    return (
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={"xl"}>
            <ModalOverlay bg='blackAlpha.300'
                backdropFilter='blur(10px)' />
            <ModalContent>
                <ModalHeader>{attachment.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={8}>
                    <Image boxSize={"80vh"} objectFit={"contain"} src={attachment.imageUrl} alt="attachment" />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ViewAttachmentModel