import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { React, useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { FaEye, FaFileContract, FaPenToSquare, FaTrash } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { removeJobOffer } from '../../API/JobOffers';
import QuizAssignation from '../../Components/JobOffers/DialogJobOffers/QuizAssignation';
import TableDeletionDialog from '../../Components/JobOffers/DialogJobOffers/TableDeletionDialog';
import { formatDateForPrint } from '../../Utils/FormatDate';
import HomepageEmptyTable from '../HomepageEmptyTable';
import { useSortableData } from '../useSortableData';
import { fadeIn } from './Animation';
import { useNavigate } from 'react-router-dom'

const ListJobOffers = ({ jobOffers, reload }) => {
    const { items: sortedJobOffers, requestSort, getSortDirection } = useSortableData(jobOffers);
    const { isOpen: isOpenQuiz, onOpen: onOpenQuiz, onClose: onCloseQuiz } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [jobToAssign, setJobToAssign] = useState(null);
    const [toDeletion, setToDeletion] = useState(null)
    const [slicedData, setSlicedData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const paged = sortedJobOffers.slice(0, 7)
        setSlicedData(paged)
    }, [sortedJobOffers])

    const handleDeletion = async () => {
        try {
            if (!await removeJobOffer(toDeletion)) {
                throw new Error('Failed to fetch data');
            }
            toast({
                title: 'Offre d\'emploi supprimée'
            })
        } catch (error) {
            toast({
                title: 'Erreur lors de la suppression de l\'offre d\'emploi',
                status: 'error',
            })
        } finally {
            reload()
        }
    }

    return (
        <Flex
            flex={1}
            gridArea={"2 / 1 / 4 / 3"}
            direction={"column"}
            overflowX={{ base: "hidden", md: "unset" }}
            width={["100%", "100%", "unset"]}
            animation={`${fadeIn} .4s ease-in`}
        >
            {jobOffers && jobOffers.length === 0 ? (
                <HomepageEmptyTable />
            ) : null}

            {jobOffers && jobOffers.length > 0 ? (
                <>
                    <TableContainer width={"100%"} >
                        <Table variant='simple'>
                            <Thead>
                                <Tr userSelect={"none"}>
                                    <Th onClick={() => requestSort('name')} cursor={"pointer"}>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Text>Nom</Text>{getSortDirection('name')}
                                        </Flex>
                                    </Th>
                                    <Th onClick={() => requestSort('team')} cursor={"pointer"}>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Text>Équipe</Text>{getSortDirection('team')}
                                        </Flex>
                                    </Th>
                                    <Th onClick={() => requestSort('expirationDate')} cursor={"pointer"}>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Text>Date de fin de l'offre</Text>{getSortDirection('expirationDate')}
                                        </Flex>
                                    </Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {slicedData.map((jobOffer, index) => (
                                    <Tr key={index} cursor={"pointer"} _hover={{ bg: "gray.100" }} >
                                        <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)} >{jobOffer.name}</Td>
                                        <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{jobOffer.team.name}</Td>
                                        <Td onClick={() => navigate(`/offre-emploi/${jobOffer._id}`)}>{jobOffer.expirationDate ? formatDateForPrint(jobOffer.expirationDate) : "Non renseigné"}</Td>

                                        <Menu>
                                            <MenuButton
                                                as={IconButton}
                                                aria-label="Options"
                                                icon={<BsThreeDots />}
                                                variant="ghost"
                                                rounded="full"
                                            />
                                            <MenuList>
                                                <MenuItem display={"flex"} gap={2} as={Link} to={`/offre-emploi/${jobOffer._id}`}><FaEye /> Visualiser</MenuItem>
                                                <MenuItem display={"flex"} gap={2} onClick={() => { setJobToAssign(jobOffers); onOpenQuiz() }}><FaFileContract /> Ajouter un questionnaire</MenuItem>
                                                <MenuItem display={"flex"} gap={2} as={Link} to={`/offre-emploi/update/${jobOffer._id}`}><FaPenToSquare color="#009688" /> Mettre à jour</MenuItem>
                                                <MenuItem display={"flex"} gap={2} onClick={() => { setToDeletion(jobOffer._id); onOpen() }}> <FaTrash color="#d32f2f" /> Supprimer</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <QuizAssignation isOpen={isOpenQuiz} onClose={onCloseQuiz} jobOffer={jobToAssign} setJobToAssign={setJobToAssign} />
                    <TableDeletionDialog isOpen={isOpen} onClose={onClose} toDeletion={toDeletion} setToDeletion={setToDeletion} handleDeletion={handleDeletion} />
                </>
            ) : null}
        </Flex>
    )
}

export default ListJobOffers
